import { FormInputProps } from "../../types/formInputProps"
//import FormInputDropdown from "./form-component/FormInputDropDown"
import FormInputDropMultiSelect from "./form-component/FormInputDropMultiSelect"

const ZodiakDropDown = ({ name, labelString, control, setValue, options }: Omit<FormInputProps, 'label'>) => {
    return (
        <>
            <FormInputDropMultiSelect
                name={name}
                control={control}
                setValue={setValue}
                label={labelString}
                options={options}
            />
        </>
    )
} // Выберите знак зодиака



export default ZodiakDropDown

