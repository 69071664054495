import { Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, Select } from "@mui/material";
import { pink } from "@mui/material/colors";
import { height, width } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { FormInputProps } from "../../../types/formInputProps";
import {SelectChangeEvent} from "@mui/material/Select";
import T from "../OnlyText";

interface CheBoxProps extends Omit<FormInputProps, 'label'> {

}

const FormInputLangLoves: React.FC<CheBoxProps> = ({
    name,
    control
}) => {
    const options = [
        "Одобрение",
        //"Прикосновения",
        "Время",
        "Помощь",
        "Подарки",
    ];
    const updateSelection = (event: any, value: string) => {
        event.persist();
        const name = event.target.name;
        localStorage.setItem('prelanglove1', value);
    };

    // onChange={(event) => updateSelection(event, option.id)}

    return (
        <Controller
            control={control}
            name={name}
            render={
                ({ field: { onChange, value } }) => (
                    <RadioGroup
                        row
                        aria-labelledby={`input-radio-${name}`}
                        name={"position" + name}
                        id={"input-radio-" + name}
                        defaultValue="top"
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        {options.map(option => {
                            return (
                                <FormControlLabel
                                    key={option + name}
                                    value={option ?? ' '}
                                    sx={{
                                        border: ['1px', 'solid', 'rgb(103 38 255)'],
                                        color: 'white',
                                        borderRadius: '5px',
                                        backgroundColor: '#8768c8',
                                        margin: '5px',
                                        width: 'calc(50% - 18px)'
                                    }}
                                    label={option}
                                    labelPlacement="start"
                                    disabled={option === "Прикосновения"}
                                    onChange={(e) => {
                                        onChange(e)
                                        updateSelection(e, option)
                                    }}
                                    control={<Radio checked={value === option} />}
                                />
                            );
                        })}
                    </RadioGroup>
                )}
        />
    );
};

export default FormInputLangLoves

