import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import '../../button-quiz.css';
interface dialogProps {
    mainPage?: boolean
    fullScreenDialog?: boolean
}

const ScrollDialog: React.FC<dialogProps> = ({ children, mainPage, fullScreenDialog = false }) => {

    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('body');

    const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <>
            {/* <Button onClick={handleClickOpen('paper')}>scroll=paper</Button> */}
            {
                /*
                sx={{
                    height: '50px',
                    width: '60%',
                    color: '#fff',
                    // backgroundColor:'#f99df0',
                    // backgroundImage: 'linear-gradient(155deg, #8768c8, #f99df0)',
                    // backgroundImage: 'linear-gradient(0, #537df6, #3c3c74)',
                    minWidth: '233px',
                    maxWidth: '238px',
                    cursor: 'pointer',
                    display: 'block',
                    textAlign: 'center',
                    textDecoration: 'none',
                    fontWeight: '800',
                    textTransform: 'uppercase',
                    borderRadius: '6px',
                    margin: '20px auto 20px auto',
                    padding: '10xp 5px',
                    backgroundSize: '200% auto',
                    boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
                    transition: '0.5s',
                    backgroundColor: '#537df6',
                    borderColor: '#537df6',
                    // backgroundImage: 'linear-gradient(155deg, #8768c8, #f99df0)'
                    // background-image: 'linear-gradient(0, #537df6, #3c3c74)',
                    // backgroundImage: 'linear-gradient(to right, #895cf2 0%, #ffabf4 50%, #895cf2 100%)',
                    backgroundImage: 'linear-gradient(to top, #3c3c74 0%, #537df6 50%, #3c3c74 200%)',
                    '&:focus': {
                        animation: 'pulse 1s',
                        boxShadow: '0 0 0 2em transparent'
                    },
                     '&:hover': {
                        //backgroundPosition: 'bottom center'
                         animation: 'pulse 1s',
                         boxShadow: '0 0 0 2em transparent'
                    }
                 }}

                 fill
                 pulse
                 close
                 raise
                 up
                 slide
                 offset
                 */
            }
            {mainPage ? <Button
                onClick={handleClickOpen('body')}
                className='quiz raise'
            >
                    ЗАПОЛНИТЬ АНКЕТУ
            </Button> : <Button onClick={handleClickOpen('body')}>показать диалоговое окно</Button>}
            
            <Dialog
                open={open}
                fullScreen={fullScreenDialog}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogContent dividers={scroll === 'paper'} 
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}>
                  {children}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Закрыть</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}


export default ScrollDialog