import { Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, Select } from "@mui/material";
import { pink } from "@mui/material/colors";
import { height, width } from "@mui/system";
import React from "react";
import { Controller } from "react-hook-form";
import { FormInputProps } from "../../../types/formInputProps";

interface CheBoxProps extends Omit<FormInputProps, 'label'> {
    options: string[]
}

const FormInputRadioButtonAdmin: React.FC<CheBoxProps> = ({
    name,
    control,
    options,
    defaultValue
}) => {
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={
                ({ field: { onChange, value } }) => (
                    <RadioGroup
                        row
                        aria-labelledby={`input-radio-${name}`}
                        name={"position" + name}
                        id={"input-radio-" + name}
                        // sx={{
                        //     flexDirection: 'column',
                        //     alignContent: 'flex-start',
                        //     minHeight: '163px',

                        // }}
                        defaultValue={defaultValue}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        {options.map((option: string) => {
                            return (
                                <FormControlLabel
                                    key={option + name}
                                    value={option}
                                    sx={{
                                        border: ['1px', 'solid', 'rgb(103 38 255)'],
                                        color: 'white',
                                        borderRadius: '5px',
                                        backgroundColor: '#8768c8',
                                        margin: '5px',
                                        width: 'calc(50% - 16px)'
                                    }}
                                    label={option}
                                    labelPlacement="start"
                                    onChange={onChange}
                                    control={<Radio checked={value === option} />}
                                />
                            );
                        })}
                    </RadioGroup>
                )}
        />
    );
};
// control={<Radio checked={value === option} />}

export default FormInputRadioButtonAdmin

