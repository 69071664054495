import { FC, useCallback, useEffect} from "react";
import FormInputCheckbox from "../quiz/form-component/FormInputCheckbox";
import { Checkbox, FormControlLabel, FormGroup, Select } from "@mui/material";
import { Button, Card, CardActionArea, CardContent, CardMedia, styled, TextField, Typography } from "@mui/material"
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack"
import { useAppSelector } from "../../hooks/hooks";
import {useAuthAccountMutation, useGetApiQuery, useSubmitDataMutation} from "../../services/goroskop";
import EnhancedTable from "./constituents/Table";
//import EnhancedTable from "./constituents/Table";
import {Data} from "../../types/data";
import {LoginType} from "../../types/login";
import {SnippetType} from "../../types/snippet";
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import {QuizType} from "../../types/quiz";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormInputTextDefaultValue from "../quiz/form-component/FormInputTextDefaultValue";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#175365',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


interface requestDat {
    status?: string | number | boolean,
    text?: string | undefined | null,
    msg?: string | undefined | null
}

type ItemOption = { value: string; label?: string }
interface TariffType extends Data {
    admin_name: string | undefined,
    name: string | undefined,
    description: string | undefined,
    premium: ItemOption | undefined,
    rule_month: ItemOption | undefined,
    rule_always_view: ItemOption | undefined,
    rule_always_coins: ItemOption | undefined,
    rule_from_percent: string | undefined,
    add_time: string | null | undefined,
    sort: string,
}

const eastSymbols = ['chicken', 'dog', 'dragon', 'horse', 'monkey', 'ox', 'pig', 'rabbit', 'rat', 'sheep', 'snake', 'tiger'];
const westSymbols = ['aries', 'taurus', 'gemini', 'cancer', 'leo', 'virgo', 'libra', 'scorpio', 'sagittarius', 'capricorn', 'aquarius', 'pisces'];

const Tariff: FC = () => {
    // const { register, handleSubmit, formState: { errors }, control, setValue } = useForm<QuizType>({
    //     defaultValues: defaultValues
    // });
    const { register, handleSubmit,  control} = useForm<TariffType>();
    const [submitData, { error, data, isError, isSuccess }] = useSubmitDataMutation()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    //const { data, error, isLoading, currentData } = useGetApiQuery(urlTargetGet)
    const tariffList = useGetApiQuery('tariffs.php?do=get');
    //const { data, error, isLoading } = useGetApiQuery('line5_oo.php')

    const onSubmit: SubmitHandler<TariffType> = subData => {
        let access = false;
        //console.log('onSubmit');
        const fd = new FormData();
        if(subData) {
            for (const [key, value] of Object.entries(subData)) {
                //fd.append('val', subData)
                //console.log(`${key}: ${value}`);
                fd.append(`${key}`, `${value}`);
                if(!access){
                    access = true;
                }
            }
        }
        console.log('subData',subData);
        if(access) {
            //fd.append('content', subData.content)
            // fd.append('pass', subData.password)
            submitData({name: 'tariffs.php?do=set', data: fd}).then((answer:any) => {
                if(answer.data && answer.data[0]){
                    enqueueSnackbar(
                        (answer.data[0].msg ? answer.data[0].msg : 'Ошибка передачи данных на сервер'),
                        {variant: answer.data[0].status ? 'success' : 'error'}
                    )
                }
            })
        }else{
            enqueueSnackbar('Ошибка передачи данных на сервер', {
                variant: 'error',
            })
        }
    };
    const printFormArea = (data:any) => {
        /*if(data[0].status && data[0].data) {
            console.warn('printFormArea data', data[0].data);
            (data[0].data).map((row: any[]) =>{
                console.log('row',row);
            });
        }*/
        if(data[0].status && data[0].data) {
            return (
                <form onSubmit={handleSubmit(onSubmit)} style={{marginBottom: '50px'}}>
                    <TableContainer component={Paper}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Имя</StyledTableCell>
                                <StyledTableCell align="center">Название для клиента</StyledTableCell>
                                <StyledTableCell align="center">Описание</StyledTableCell>
                                <StyledTableCell align="center">VIP</StyledTableCell>
                                <StyledTableCell align="center">За прошлый месяц</StyledTableCell>
                                <StyledTableCell align="center">Всегда видит новых</StyledTableCell>
                                <StyledTableCell align="center">Может исп. ключи</StyledTableCell>
                                <StyledTableCell align="center">%</StyledTableCell>
                                <StyledTableCell align="center">Только фото</StyledTableCell>
                                <StyledTableCell align="center">Добав. времени</StyledTableCell>
                                <StyledTableCell align="center">Сорт</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(data[0].data).map((row: TariffType) => {
                                return (<StyledTableRow key={row.id}>
                                            <StyledTableCell component="th" scope="row">{row.admin_name}</StyledTableCell>
                                            <StyledTableCell align="right"><FormInputTextDefaultValue defaultValue={(row.name ?? '')} control={control}
                                                // @ts-ignore
                                                name={`${row.id}-name`} label="Имя"
                                            /></StyledTableCell>
                                            <StyledTableCell align="left">{row.description}</StyledTableCell>
                                            <StyledTableCell align="center"><FormInputCheckbox control={control}
                                                // @ts-ignore
                                                name={`${row.id}-premium`} defaultValue={row.premium}
                                            /></StyledTableCell>
                                            <StyledTableCell align="center"><FormInputCheckbox control={control}
                                                // @ts-ignore
                                                name={`${row.id}-rule_month`} defaultValue={row.rule_month}
                                            /></StyledTableCell>
                                            <StyledTableCell align="center"><FormInputCheckbox control={control}
                                                // @ts-ignore
                                                name={`${row.id}-rule_always_view`} defaultValue={row.rule_always_view}
                                            /></StyledTableCell>
                                            <StyledTableCell align="center"><FormInputCheckbox control={control}
                                                // @ts-ignore
                                                name={`${row.id}-rule_always_coins`} defaultValue={row.rule_always_coins}
                                            /></StyledTableCell>
                                            <StyledTableCell align="right"><FormInputTextDefaultValue
                                                defaultValue={(row.rule_from_percent ?? '')} control={control}
                                                // @ts-ignore
                                                name={`${row.id}-rule_from_percent`} label="От %"
                                            /></StyledTableCell>
                                            <StyledTableCell align="center"><FormInputCheckbox control={control}
                                                // @ts-ignore
                                                name={`${row.id}-rule_only_view`} defaultValue={row.rule_only_view}
                                            /></StyledTableCell>

                                            <StyledTableCell align="right">{row.add_time}</StyledTableCell>
                                            <StyledTableCell align="right"><FormInputTextDefaultValue defaultValue={(row.sort ?? '')} control={control}
                                                // @ts-ignore
                                                name={`${row.id}-sort`} label="Сортировка"
                                            /></StyledTableCell>
                                        </StyledTableRow>);
                            })}
                        </TableBody>
                    </TableContainer>
                    <Typography style={{width: '100%', textAlign: 'center', padding: '5px 0 0 0'}}>
                        <Button type="submit" variant="contained" >Сохранить настройки тарифов</Button>
                    </Typography>
                </form>
            );
        } else {
            if(data.data[0].msg){
                enqueueSnackbar(data.data[0].msg, {
                    variant: 'error',
                })
                return (<>{data.data[0].msg}</>);
            }else {
                enqueueSnackbar('Упс... Ошибка на сервере', {
                    variant: 'error',
                })
            }
        }
        return (<>Ошибка</>);
    }

    return(
        <>
            {tariffList.isError && <h1>Ошибка</h1>}
            {tariffList.isLoading && <h1>Загрузка, подождите пожалуйста</h1>}
            {tariffList.isSuccess && printFormArea(tariffList.data)}
        </>
    )
}



export default Tariff