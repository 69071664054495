import { Box, Button, Card, CardActionArea, CardContent, CardMedia,  TextField, Typography } from "@mui/material";
import React, {forwardRef, useEffect, useRef, useState} from "react";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { increment, decrement } from "../reducers/quizSlice";
import  { ReactInputMask } from 'react-input-mask';
import {  Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { QuizType } from "../types/quiz";
import FormInputText from "./quiz/form-component/FormInputText";
import FormInputNumber from "./quiz/form-component/FormInputNumber";
import FormInputDropdown from "./quiz/form-component/FormInputDropDown";
import FormInputTextAria from "./quiz/form-component/FormInputTextArea";
import T from "./quiz/OnlyText";
import FormInputReactMask from "./quiz/form-component/FormInputReactMask";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import { useSnackbar } from "notistack";
import FormInputRadioButton from "./quiz/form-component/FormInputRadioButtons";
import FormInputDate from "./quiz/form-component/FormInputDate";
import FormInputDateNEW from "./quiz/form-component/FormInputDateNEW";
import {useSubmitDataMutation, useSubmitDataWithRerenderMutation} from "../services/goroskop";
import { purple, red } from "@mui/material/colors";
import { styled } from "@mui/system";
import ScrollDialog from "./quiz/Dialog";
import Pass from "./quiz/Pass";
import Email from "./quiz/Email";
import { Link } from "react-router-dom";
import {FormInputUniversalText} from "./quiz/form-component/FormInputUniversalText";
import ZodiakList from "./quiz/zodiakList";
import LangLoveList from "./quiz/langLoveList";
import regionCodes from "./forAll/regions";
import genderList from "./forAll/genders";
import '../modal.css';
import '../form.css';
import AgreementPopUp from './quiz/agreement';
import FormInputLangLoves from "./quiz/form-component/FormInputLangLoves";
import FormInputLangLoves2 from "./quiz/form-component/FormInputLangLoves2"; // Заполняя заявку, вы соглашаетесь на
import FormInputRangeSlider from "./quiz/form-component/FormInputRangeSlider"; // Заполняя заявку, вы соглашаетесь на

import { useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import dayjs, { Dayjs } from 'dayjs';

import 'dayjs/locale/ru' // import locale
dayjs.locale('ru') // use locale

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {Data} from "../types/data";
import {MainState} from "../reducers/mainSlice";
import {setCookie} from "../functions/Cookie";

import { getUA } from 'react-device-detect';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Moscow');

const imgFields:any[] = ['o_img1','o_img2','o_img3','o_img4'];
const imgInputIds = ['o_img1', 'o_img2', 'o_img3', 'o_img4'];
const Input = styled('input')({
    display: 'none',
});
const getLanglove2Access = (love1: string, love2: string) => {
    let access: any[] = [];
    switch (love1){
        case 'Одобрение':{  // Одобрение
            access = ["Прикосновения", "Время", "Помощь", "Подарки"]; // Прикосновения, Время, Помощь, Подарки
        }break;
        case 'Время':{  // Время
            access = ["Прикосновения", "Помощь", "Подарки"]; // Прикосновения, Помощь, Подарки
        }break;
        case 'Помощь':{  // Помощь
            access = ["Прикосновения", "Подарки"]; // Прикосновения, Подарки
        }break;
        case 'Подарки':{  // Подарки
            access = ["Прикосновения"]; // Прикосновения
        }break;
        case 'Прикосновения':
        default: {
            access = [];
        } break;
    }
    return access.includes(love2);
}

interface ImmageArr {
    o_img1: File | string | null;
    o_img2: File | string | null;
    o_img3: File | string | null;
    o_img4: File | string | null
}

const Form = styled('form')({
    // border: '1px dashed grey',
    textAlign: 'center',
    // width: '800px',
    backgroundColor: 'white',
    minHeight: "700px",
    position: 'relative',
    borderRadius: '10px',
    '& > div': {
        border: '1px solid rgb(80, 61, 122)',
        borderRadius: '8px',
    },
    '& > div > label.MuiFormLabel-filled': {
        display: 'none'
    },
    '& > div > label.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root': {
        display: 'none'
    },

});

// Regions: read: https://www.autonews.ru/news/633febdb9a79473e43223d37#p2

const email = Date.now() + Math.random()

const Quiz = ({ quizRef }: { quizRef?: ((instance: unknown) => void) | React.MutableRefObject<unknown> | null }) => {
    const imgArray = Array(null, null, null, null);
    const [imageArr, setImageArr] = useState<ImmageArr>({
        o_img1: imgArray[0],
        o_img2: imgArray[1],
        o_img3: imgArray[2],
        o_img4: imgArray[3]
    })

    const newString = Object.values(imageArr)
    const [imageNameArr, setimageNameArr] = useState<string[]>([])

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [submitData, {data, error}] = useSubmitDataMutation()
    const { value } = useAppSelector(state => state.quiz)
    const dispatch = useAppDispatch()
    const { register, handleSubmit, formState: { errors }, control, setValue, watch } = useForm<QuizType>({defaultValues: {
        fav: "",
        fav_date: "",
        fav_modify: "",
        color: "",
        comp: "[]",
        firstname: "",
        gender: "Ж",
        city: "",
        birthday: dayjs('1970-01-01T00:00:00.000'),
        year: "",
        birthyear: "",
        zodiak: "",
        langlove: "",
        langlove2: "",
        familystatus: "",
        phone: "",
        vk: "",
        helptext: "",
        targetsearch: "",
        targetsearchtext: "",
        about: "",
        height: "",
        weight: "",
        inst: "",
        fb: "",
        ok: "",
        email: "",
        pass: "",
        r_pass: "",
        dateofend: dayjs(),
        source: "",
        source_type: "Квиз",
        images: "",
        o_img1: "",
        o_img2: "",
        o_img3: "",
        o_img4: "",
        registermonth_new: "",
        smoke: "",
        children: "",
        birthdaychild1: "",
        birthdaychild2: "",
        birthdaychild3: "",
        birthdaychild4: "",
        lastlove: "",
        lastzodiak: "",
        defer: "0",
        report: "",
        politic: false,
        vip: "0",
    }});

    const password = useRef({});
    password.current = watch("pass", "");

    const firstname = useRef({});
    firstname.current = watch("firstname").length;

    const phone = useRef({});
    phone.current = watch("phone").length;

    const activeNext = true;

    const ageCfg = {
        maxDifference: 20,
        minusMan: 20,
        plusMan: 8,
        minusWoman: 8,
        plusWoman: 20,
    };
    let tmpMinRange: number, tmpMaxRange: number,
        age: number, ageStorage: string | null;
    ageStorage = localStorage.getItem('age');
    age = 0;
    if(ageStorage) {
        age = parseInt(ageStorage);
        tmpMinRange = age - ageCfg.maxDifference;
        if(tmpMinRange < 18){
            tmpMinRange = 18;
        }

        tmpMaxRange = age + ageCfg.maxDifference;
        if(tmpMaxRange > 100){
            tmpMaxRange = 100;
        }
    }

    const tmpMinValue = localStorage.getItem('ageFrom')
    const tmpMaxValue = localStorage.getItem('ageUntil')
    const minRangeValue = tmpMinValue !== null ? JSON.parse(tmpMinValue) : 18;
    const maxRangeValue = tmpMaxValue !== null ? JSON.parse(tmpMaxValue) : 100;

    const getFamilyStatutes = () => {
        let gender = localStorage.getItem('gender');
        if(gender){
            switch (gender){
                case 'М':
                    return ['Холост', 'Женат'];
                    break;
                case 'Ж':
                    return ['Не замужем', 'Замужем'];
                    break;
            }
        }
        return ['Холост', 'Не замужем', 'Женат', 'Замужем'];
    }

    const getFamilyStatusDefault = () => {
        let gender = localStorage.getItem('gender');
        if(gender){
            switch (gender){
                case 'М':
                    return 'Холост';
                    break;
                case 'Ж':
                    return 'Не замужем';
                    break;
            }
        }
    }

    const getVozrast = (date: Dayjs | null) => {
        // return date; - 1971-07-27
        if(date) {
            let dd: string | number = (date.date()),
                mm: string | number = (date.month()) + 1,
                yyyy: string | number = (date.year());

            let today = new Date();
            //@ts-ignore
            let birthDate = new Date(yyyy,mm,dd);

            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }
        return 0;
    }

    const handleValidation = (showMessage: boolean) => {
        let errors = {};
        let formIsValid = true;
        let message = '';
        let data = {
            email: watch("email"),
            pass: watch("pass"),
            pass2: watch("passwordConfirm")
        }
        if(data.email && data.email.length > 0 || data.pass && data.pass.length > 0 || data.pass2 && data.pass2.length > 0)
        {
            if (data.email.length == 0 && data.pass.length > 0) {
                formIsValid = false;
                message = 'Поле email обязательно для заполнения';
            } else if (!data.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                formIsValid = false;
                message = 'Проверьте пожалуйста правильность введеного email';
            } else if (data.pass == '') {
                formIsValid = false;
                message = 'Поле Пароль обязательно для заполнения';
            } else if (data.pass.length < 6) {
                formIsValid = false;
                message = 'Минимальная длина пароля - 6 символов';
            } else if (data.pass2 !== data.pass) {
                formIsValid = false;
                message = 'Пароли не совпадают';
            }
            if(showMessage){
                return message;
            }
        }
        return formIsValid;
    }

    let navigate = useNavigate();
    const authMe = (data: {
        Account: Data,
        whoIs: string,
        token: string,
        status: boolean,
        msg: string,
        type: string,
        gender: number,
        color: number,
        use_coins: boolean
    }) =>{
        if(data.status === true) {
            const whoIs = data.whoIs

            if (whoIs !== '[]' && data.token) {
                let authData: MainState = {
                    Account: data.Account,
                    role: data.whoIs,
                    easyRole: "unlogined",
                    token: data.token,
                    color: data.color,
                    use_coins: data.use_coins
                }

                setCookie('menu', (data.color).toString(), 7)

                if(data.gender) {
                    setCookie('gender', (data.gender).toString(), 7)
                }
                setCookie('role', whoIs, 7)
                setCookie('token', authData.token, 7)

                if(typeof(data.use_coins) === 'boolean') {
                    if(data.use_coins) {
                        setCookie('use_coins', '1', 7)
                    }else{
                        setCookie('use_coins', '0', 7)
                    }
                }

                //@ts-ignore
                if(typeof(data.Account.tariff_coins) !== 'undefined'){
                    //@ts-ignore
                    setCookie('coins',data.Account.tariff_coins, 7)
                }

                authData.easyRole = "user";
                navigate(`../cabinet/${data.Account.id}`, {replace: true})
            } else {
                enqueueSnackbar('Ошибка авторизации', {
                    variant: 'error',
                })
            }
        }else{
            if(data.msg) {
                if(data.msg == 'moderator'){
                    enqueueSnackbar('Учетная запись не активна, обратитесь пожалуйста к администрации для ускоренного подтверждения ваших данных.', {
                        variant: 'info'
                    })
                }else {
                    enqueueSnackbar(data.msg, {
                        variant: data.status ? 'success' : 'error'
                    })
                }
            }
        }
    }

    const onSubmit: SubmitHandler<QuizType> = dataQuiz => {
        let returnMe = true;
        let newdataQuiz = dataQuiz;
        if (value > 6 && value < 21) {
            newdataQuiz.color = 'red'
        } else if (value === 21) {
            newdataQuiz.color = 'yellow'
        } else {
            newdataQuiz.color = 'white'
        }
        newdataQuiz.images = imageNameArr.join(',');

        let tmpStep = localStorage.getItem('step');
        let latestStep: number = (tmpStep === null ? 0 : parseInt(tmpStep));
        if(value != latestStep){
            localStorage.setItem('step', value.toString());
        }

        if((dataQuiz.firstname).length < 3){
            returnMe = false;
            dispatch(decrement());
            enqueueSnackbar('Как вас зовут?', {variant: 'info'})
        }

        switch(value){
            case 1:
                if(newdataQuiz.gender && newdataQuiz.gender !== '') {
                    localStorage.setItem('gender', newdataQuiz.gender);
                }
                break;
            case 2: // устанавливаем диапазон желаемого возраста по умолчанию
                if(newdataQuiz.gender && newdataQuiz.gender !== '') {
                    localStorage.setItem('gender', newdataQuiz.gender);
                }
                if(dataQuiz.birthday){
                    //@ts-ignore
                    let year: number = (dataQuiz.birthday).year() + 1;
                    let currYear = new Date().getFullYear();
                    if( (year > 1900) && (year <= currYear) ){
                        //@ts-ignore
                        let age = getVozrast(dataQuiz.birthday);
                        if(age >= 0) {
                            localStorage.setItem('age', age.toString());
                            let agesMinus: number = 20;
                            let agesPlus: number = 20;
                            let gender = localStorage.getItem('gender');
                            switch (gender){
                                case 'М':
                                    agesMinus = ageCfg.minusMan;
                                    agesPlus = ageCfg.plusMan;
                                    break;
                                case 'Ж':
                                    agesMinus = ageCfg.minusWoman;
                                    agesPlus = ageCfg.plusWoman;
                                    break;
                                default:
                                    agesMinus = 20;
                                    agesPlus = 20;
                                    break;
                            }
                            let ageUntil:string = '100';
                            let ageUntilTmp = (age + agesPlus);
                            if (ageUntilTmp <= 100) {
                                ageUntil = ageUntilTmp.toString();
                            }
                            localStorage.setItem('ageUntil', ageUntil);

                            let ageFrom:string = '18';
                            let ageFromTmp = (age - agesMinus);
                            if(ageFromTmp >= 18){
                                ageFrom = ageFromTmp.toString();
                            }
                            localStorage.setItem('ageFrom', ageFrom);
                        }
                    }
                }
                break;
            case 4:
            case 5:
            case 6:
                if(dataQuiz.langlove2 && !getLanglove2Access(dataQuiz.langlove, dataQuiz.langlove2)){
                    setValue('langlove2', '');
                }
                switch(value){
                    case 5:
                        if(dataQuiz.langlove == ''){
                            dispatch(decrement())
                            enqueueSnackbar('Необходимо заполнить языки любви', {variant: 'info'})
                        }
                        break;
                    case 6:
                        if(dataQuiz.langlove2 == ''){
                            dispatch(decrement())
                            enqueueSnackbar('Необходимо заполнить языки любви', {variant: 'info'})
                        }
                        break;

                }
                break;
            case 8:
                if((dataQuiz.phone).length < 17){
                    returnMe = false;
                    dispatch(decrement());
                    enqueueSnackbar('Необходимо заполнить телефон', {variant: 'info'})
                }
            break;
            case 18:
                if(newdataQuiz.children == 'Да'){
                    localStorage.setItem('children', 'Y');
                }else{
                    localStorage.setItem('children', 'N');
                }
                break;
            case 19:
                if(dataQuiz.children !== 'Да'){
                    if(value > latestStep){ // next
                        localStorage.setItem('step', (value + 2).toString());
                        dispatch(increment());
                    }else if(value < latestStep){ // before
                        localStorage.setItem('step', (value - 2).toString());
                        dispatch(decrement())
                    }
                }
                break;
            case 22:
                if(!(dataQuiz.email.length > 3 && dataQuiz.pass.length > 6 && (dataQuiz.passwordConfirm == dataQuiz.pass))){
                    /*if (dataQuiz.email.length == 0) {
                        enqueueSnackbar('Email обязателен для авторизации', {variant: 'info'})
                    } else if (dataQuiz.pass.length < 6) {
                        enqueueSnackbar('Пароль обязателен для авторизации', {variant: 'info'})
                    } else if (dataQuiz.passwordConfirm !== dataQuiz.pass) {
                        enqueueSnackbar('Пароли не свопадают', {variant: 'info'})
                    }*/
                    dispatch(decrement());
                }
                break;
        }
        if(returnMe){
            let fd = new FormData()

            const tmpAgeFrom = localStorage.getItem('ageFrom')
            const tmpAgeUntil = localStorage.getItem('ageUntil')
            newdataQuiz.ageFrom = tmpAgeFrom !== null ? JSON.parse(tmpAgeFrom) : 18;
            newdataQuiz.ageUntil = tmpAgeUntil !== null ? JSON.parse(tmpAgeUntil) : 100;

            if(newdataQuiz.birthday) {
                try {
                    //@ts-ignore
                    newdataQuiz._birthday = (newdataQuiz.birthday.format('DD.MM.YYYY'));
                }catch (e){
                    newdataQuiz._birthday = '';
                }
            }

            if(newdataQuiz.dateofend) {
                try {
                    //@ts-ignore
                    newdataQuiz._dateofend = (newdataQuiz.birthdaydateofend.format('DD.MM.YYYY'));
                }catch (e){
                    newdataQuiz._dateofend = '';
                }
            }

            fd.append('data', JSON.stringify(newdataQuiz))
            value === 7 && fd.append('step', value.toString())
            fd.append('email', email.toString())
            fd.append('useragent', getUA);

            if (value > 7) {
                submitData({name: 'quiz.php', data: fd}).then((answer:any) => {
                    if (answer.data && answer.data.Account) {
                        authMe(answer.data);
                    }
                })
            }
            if (error) {
                console.log({error: error, data: newdataQuiz});
            }
            let res = data;
            try {
                /* @ts-ignore */
                if (typeof (res.result) !== 'undefined' && res.msg !== 'undefined') {
                    /* @ts-ignore */
                    if (res.result == true) {
                        /* @ts-ignore */
                        if (res.msg && res.msg != 'ok update') {
                            enqueueSnackbar(
                                /* @ts-ignore */
                                res.msg, {
                                    variant: 'success',
                                })
                        }
                    } else { // @ts-ignore
                        /* @ts-ignore */
                        if (res.msg) {
                            enqueueSnackbar(
                                /* @ts-ignore */
                                res.msg, {
                                    variant: 'error',
                                })
                        }
                    }
                }
            } catch (e) {
                console.warn('kndev', e);
            }
        }
    };

    useEffect(() => {
        // @ts-ignore
        if (data && data.files) {
            // @ts-ignore
            setImageArr(data.files);
            // @ts-ignore
            imgFields.forEach((field) => {
                // @ts-ignore
                imageNameArr[Object.keys(data.files).indexOf(field)] = data.files[field] ? data.files[field] : 'null'
            })
        }
    });
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        let fd = new FormData()

        const file = e.target.files ? e.target.files[0] : 'null'
        const fileName = e.target.files ? e.target.files[0]?.name : 'null'
        const inputName = e.currentTarget.name

        // needed:
        imageNameArr[Object.keys(imageArr).indexOf(e.target.name)] = e.target.files ? e.target.files[0].name : 'null'

        const fR = new FileReader()

        e.target.files && fR.readAsDataURL(e.target.files[0])

        // fR.onloadend = () => {
        //     setImageArrTmp({ ...imageArrTmp, [e.target.name]: fR.result })
        //     console.log('imageArrTmp',imageArrTmp);
        // }

        setValue('images', imageNameArr.join(','))

        fd.append(e.target.name, file)
        //fd.append('user_email', email.toString())

        fd.append('images', imageNameArr.join(','))

        submitData({ name: 'updatePhoto.php', data: fd })
            .finally(() => {
                enqueueSnackbar('Фото загружено', {
                    variant: 'info',
                });
            })
    }

    const deleteImage = (nameImg: number) => {
        setImageArr({ ...imageArr, [Object.keys(imageArr)[nameImg]]: null })
        newString[nameImg] =  null
    }

    const renderForm = () => {

        switch (value) {
            case 0:
                return (
                    <>
                        <T>Имя и Фамилия как в Vkontakte</T>
                        <FormInputUniversalText
                            // label='Имя и фамилия'
                            type='text'
                            name='firstname'
                            error={!!errors.firstname?.type}
                            rules={{
                                min: 3,
                                validate: (value: any) => {
                                    return (value.length >= 3)
                                }
                            }}
                            control={control}
                            focused
                            className={`form-control ${errors.firstname || firstname.current < 3 ? 'is-invalid' : ''}`}
                        />

                        <T>Ваш пол</T>
                        <FormInputDropdown
                            control={control}
                            name='gender'
                            label="Выберите пол"
                            options={genderList}
                        />

                        <T>Выберите код вашего регионa</T>
                        <FormInputDropdown
                            control={control}
                            name='city'
                            label="Выберите код регионa"
                            options={regionCodes}
                        />
                        {/*<div
                            className="invalid-feedback"
                            style={
                                ((errors.firstname?.type) || (firstname.current < 3))
                                    ? {}
                                    : {display: "none"}
                            }
                        >{ ((errors.firstname?.type) || (firstname.current < 3)) ? 'Поле имя - обязательно для заполнения' : ''}</div>
                        */}
                        {((errors.firstname?.type) || (firstname.current < 3)) ?
                        <Alert severity="error" sx={{
                            margin: '20px 0 0 0',
                            textAlign: 'left'
                        }}>
                            Поле имя - обязательно для заполнения
                        </Alert>
                        : '' }

                        {/*<T>Продолжая, вы соглашаетесь на <a href={'#'} onClick={
                            Popup.alert('I am alert, nice to meet you')
                        }>обработку персональных данных</a>.</T>*/}
                        <AgreementPopUp welcomeText='Заполняя заявку, вы соглашаетесь на'/>
                        {/*
                        <T>Введите код регионa. Если Вы не занете код Вашего региона нажмите:</T>
                        <ScrollDialog >
                            <CardMedia
                                component="img"
                                height="90%"
                                image={`/images/region_code.jpg`}
                                alt={'Код региона Россия'}
                            />
                        </ScrollDialog>*/}
                    </>
                )
            case 1:
                return(
                    <>
                        <T>Дата вашего рождения?</T>
                        <FormInputDateNEW
                            control={control}
                            // label='Дата рождения'
                            name="birthday"
                        />
                    </>
                )
            case 2:
                return(
                    <>
                        <T>
                            В год какого животного вы родились?
                            Если вы не знаете в год какого животного вы родились,
                            то дата начала и конец вашего года указаны :
                        </T>
                        <ScrollDialog >
                            <CardMedia
                                component="img"
                                height="90%"
                                image={`/images/anim_year.jpg`}
                                alt={'Знаки зодиака по дате'}
                            />
                        </ScrollDialog>
                        <LangLoveList name="birthyear" control={control} setValue={setValue} />

                    </>
                )
            case 3:
                return (
                    <>
                        <T>
                            Кто вы по знаку зодиака? Если не знаете свой знак зодиака,
                            то посмотрите подсказку:
                        </T>
                        <ScrollDialog >
                            <CardMedia
                                component="img"
                                height="90%"
                                image={`/images/zodiak_year.jpg`}
                                alt={'Знаки зодиака по дате'}
                            />
                        </ScrollDialog>
                        <ZodiakList name="zodiak" control={control} setValue={setValue} />
                    </>
                )
            case 4:
                return (
                    <>
                        <T>Выберете пожалуйста 2 своих психологических языка любви.
                            Это то, как вы понимаете, что вас любят,
                            и вы готовы так же выражать свою любовь.
                            Языки любви посмотреть:
                        </T>
                        <ScrollDialog >
                            <CardMedia
                                component="img"
                                height="90%"
                                image={`/images/lang_love.jpg`}
                                alt={'Языки любви'}
                            />
                        </ScrollDialog>
                        {/*<T>Основной язык любви:</T>
                        <FormInputRadioButton
                            control={control} name='langlove'
                            options={['Одобрение', 'Время', 'Помощь', 'Подарки', ]} // Первое нельзя помощь, а потом время, сначала время а потом помощь
                        />
                        <T>Второстепенный язык любви:</T>
                        <FormInputRadioButton
                            control={control} name='langlove2'
                            options={['Одобрение', 'Прикосновения', 'Время', 'Помощь', 'Подарки', ]}
                        />*/}
                        <T>Основной язык любви</T>
                        <FormInputLangLoves
                            control={control}
                            name='langlove'
                        />
                    </>
                )
            case 5:
                return (
                    <>
                        <T>Выберете пожалуйста 2 своих психологических языка любви.
                            Это то, как вы понимаете, что вас любят,
                            и вы готовы так же выражать свою любовь.
                            Языки любви посмотреть:
                        </T>
                        <ScrollDialog >
                            <CardMedia
                                component="img"
                                height="90%"
                                image={`/images/lang_love.jpg`}
                                alt={'Языки любви'}
                            />
                        </ScrollDialog>
                        <T>Второстепенный язык любви</T>
                        <FormInputLangLoves2
                            control={control}
                            name='langlove2'
                            forceupdate={false}
                        />
                    </>
                )
            case 6:
                return (
                    <>
                        <T>
                            Ваше семейное положение
                        </T>

                        <FormInputRadioButton
                            control={control} name='familystatus'
                            options={getFamilyStatutes()}
                            defaultValue={getFamilyStatusDefault()}
                        />
                    </>
                )
            case 7:
                return (
                    <>
                        <T>Введите ваш номер телефона</T>
                        <FormInputReactMask
                            control={control}
                            name='phone'
                            /*error={!!errors.firstname?.type}*/
                            rules={{
                                required: true,
                                min: 17,
                                validate: (value: any) => {
                                    return (value.length >= 3)
                                }
                            }}
                            className={`form-control ${errors.phone || phone.current > 3 ? 'is-invalid' : ''}`}
                        />

                        {/*<FormInputUniversalText
                            label='Телефон'
                            type='text'
                            name='phone'
                            control={control}
                            focused
                            className={`form-control ${errors.firstname || firstname.current > 3 ? 'is-invalid' : ''}`}
                        />*/}
                        {/*<div
                            className="invalid-feedback"
                            style={
                                ((errors.phone?.type) || (phone.current < 17))
                                    ? {}
                                    : {display: "none"}
                            }
                        >{ (phone.current < 17) ? 'Поле телефон - обязательно для заполнения' : ''}</div>*/}
                        {((errors.phone?.type) || (phone.current < 17)) ?
                            <Alert severity="error" sx={{
                                margin: '20px 0 0 0',
                                textAlign: 'left'
                            }}>
                                Поле телефон - обязательно для заполнения
                            </Alert>
                            : '' }
                    </>
                )
            case 8:
                return (
                    <>
                        <T>Введите ссылку на вашу страничку Vkontakte<br/>
                            [<a
                                style={{ color: red[800] }}
                                title={'Как скопировать ссылку на вк'}
                                target={'_blank'}
                                rel={'nofollow'}
                                href={'https://vk.com/faq18366'}>Инструкция</a>]
                        </T>
                        <T>Кликни что бы подставить пример: <a href={'#'} style={{ color: red[800] }} onClick={(e) => {
                            setValue('vk', 'https://vk.com/goroskoper2000')
                        }}>https://vk.com/goroskoper2000</a></T>
                        <FormInputText control={control} label='Ссылка вконтакте' name="vk" />
                    </>
                )
            case 9:
                return (
                    <>
                        <T>
                            Чем я Вам могу помочь? Что бы Вы хотели? Кого бы Вы хотели найти?
                            Просьба дать максимально развернутый ответ.
                        </T>
                        <FormInputTextAria
                            control={control}
                            label='Чем я Вам могу помочь?'
                            name='helptext'
                        />
                        <FormInputRangeSlider
                            control={control}
                            labelString='Выберите желаемый возраст партнера'
                            name='agesRange'
                            minRange={tmpMinRange}
                            maxRange={tmpMaxRange}
                            minValue={minRangeValue}
                            maxValue={maxRangeValue}
                            stepRange={1}
                            yearStep={true}
                        />
                        <Alert severity="info" sx={{
                            margin: '20px 0 0 0',
                            textAlign: 'left'
                        }}>
                            Некоторые варианты <b>запрещены</b> для выбора из рассчета <u>плохой совместимости</u> по дате рождения. По умолчанию установлен <b>рекомендуемый</b> диапазон возрастов.<br />
                        </Alert>
                    </>
                )
            case 10:
                return (
                    <>
                        <T>Вид поиска</T>
                        <FormInputRadioButton
                            control={control}
                            name='targetsearch'
                            options={['Активный', 'Пассивный', 'Консультация', 'Встреча']}
                        />
                        <FormInputTextAria
                            control={control}
                            label='Опишите, что еще вы хотели бы учесть при поиске партнера...'
                            name="targetsearchtext"
                        />
                    </>
                )
            case 11:
                return (
                    <>
                        <T>Расскажите, пожалуйста немного о&nbsp;себе.
                            Ваше Образование? Сфера деятельности? Должность?
                            Качества которыми&nbsp;Вы обладаете? Ваши Увлечения? Хобби? Достижения?
                            Напишите пожалуйста Ваш регион проживания и&nbsp;населённый пункт в&nbsp;котором вы&nbsp;проживаете.
                        </T>
                        <FormInputTextAria
                            name="about"
                            label='Расскажите о себе'
                            control={control}
                        />
                    </>
                )
            case 12:
                return (
                    <>
                        <T>Введите ваш рост, см</T>
                        <FormInputNumber
                            control={control}
                            label='Ваш рост'
                            name="height"
                            />
                        <T>Введите ваш вес, кг</T>
                        <FormInputNumber
                            control={control}
                            label='Ваш вес'
                            name="weight"
                            />
                    </>
                )
            case 13:
                return (
                    <>
                        <Typography variant="subtitle1" component="p">
                            Ваши дополнительные социальные сети
                        </Typography>
                        <T>Ссылка на Instagram</T>
                        <FormInputText control={control} label='Ссылка на Instagram' name="inst" />
                        <T>Ссылка на Facebook</T>
                        <FormInputText control={control} label='Ссылка на Facebook' name="fb" />
                        <T>Ссылка на Одноклассники</T>
                        <FormInputText control={control} label='Ссылка на Одноклассники' name="ok" />
                    </>
                )
            case 14:
                return (
                    <>
                        <Typography variant="subtitle1" component="p">
                            Дата окончания отношений
                        </Typography>
                        <FormInputDateNEW
                            control={control}
                            // label='Дата рождения'
                            name="dateofend"
                        />
                    </>
                )
                //<FormInputDate control={control} label='Дата окончания отношений' name="dateofend" />
            case 15:
                return (
                    <>
                        <Typography variant="subtitle1" component="p">
                            Откуда узнали обо мне
                        </Typography>
                        <FormInputText control={control} label='Откуда узнали обо мне' name="source" />
                    </>
                )
            case 16:
                return (
                    <>
                        <Typography variant="subtitle1" component="p">
                            Прикрепите свои лучшие 3-4 фото
                        </Typography>
                        <T sx={{width: '100%', position: 'relative'}}>
                        {
                            Object.values(imageArr).map((img, index) => {

                                return (
                                <Card sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0" }} key={index} onClick={() => deleteImage(index)}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={
                                                (img && img !== null && img.length > 0 )
                                                ? `/upload/${img}`
                                                : '/images/noImg.jpg'
                                            }
                                            alt="photo"
                                        />
                                        <CardContent >
                                            <Typography gutterBottom variant="h5" component="div" >
                                                Delete
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            )})
                        }
                        </T>
                        {/*<input {...register('o_img1')} type="file" accept="image/jpg, image/jpeg" onChange={handleChange}/>*/}
                        <label htmlFor="contained-button-file1">
                            {imgInputIds.forEach(inputId => {})}
                            <Input {...register('o_img1')} onChange={handleChange} id="contained-button-file1" type="file" accept="image/jpg, image/jpeg" />
                            <Button
                                sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0", textAlign: 'center' }}
                                variant="contained" component="span">
                                Картинка 1
                            </Button>
                        </label>
                        <label htmlFor="contained-button-file2">
                            <Input {...register('o_img2')} onChange={handleChange} id="contained-button-file2" type="file" accept="image/jpg, image/jpeg" />
                            <Button
                                sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0", textAlign: 'center' }}
                                variant="contained" component="span">
                                Картинка 2
                            </Button>
                        </label>
                        <label htmlFor="contained-button-file3">
                            <Input {...register('o_img3')} onChange={handleChange} id="contained-button-file3" type="file" accept="image/jpg, image/jpeg" />
                            <Button
                                sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0", textAlign: 'center' }}
                                variant="contained" component="span">
                                Картинка 3
                            </Button>
                        </label>
                        <label htmlFor="contained-button-file4">
                            <Input {...register('o_img4')} onChange={handleChange} id="contained-button-file4" type="file" accept="image/jpg, image/jpeg" />
                            <Button
                                sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0", textAlign: 'center' }}
                                variant="contained" component="span">
                                Картинка 4
                            </Button>
                        </label>
                        {/* images */}
                    </>
                )
            /*case 17:
                return (
                    <>
                        <Typography variant="subtitle1" component="p">
                            Выберите дату заполнения анкеты
                        </Typography>
                        <FormInputDate
                            control={control} label='Выберите дату заполнения анкеты' name="registermonth_new" />
                        {/* date * /}
                    </>
                )*/
            case 17:
                return (
                    <>
                        <Typography></Typography>
                        <T>Курение?</T>
                        <FormInputRadioButton
                            control={control}
                            name='smoke'
                            options={['Да', 'Нет', 'Не указано']}
                        />
                    </>
                )
            case 18:
                return (
                    <>
                        <T>Наличие детей</T>
                        <FormInputRadioButton
                            control={control}
                            name='children'
                            options={['Да', 'Нет', 'Не указано']}
                        />
                    </>
                )
            case 19:
                return (
                    <>
                        <Typography variant="subtitle1" component="p">
                            Возраст детей и&nbsp;их&nbsp;пол
                        </Typography>
                        <T>День рождение 1 ребенка</T>
                        <FormInputTextAria
                            control={control}
                            name='birthdaychild1'
                            label="День рождение 1 ребенка"
                        />
                        <T>День рождение 2 ребенка</T>
                        <FormInputTextAria
                            control={control}
                            name='birthdaychild2'
                            label="День рождение 2 ребенка"
                        />
                        <T>День рождение 3 ребенка</T>
                        <FormInputTextAria
                            control={control}
                            name='birthdaychild3'
                            label="День рождение 3 ребенка"
                        />
                        <T>День рождение 4 ребенка</T>
                        <FormInputTextAria
                            control={control}
                            name='birthdaychild4'
                            label="День рождение 4 ребенка"
                        />
                    </>
                )
            case 20: // Шаг 20
                return (
                    <>
                        <T>Бывший муж (парень) или жена (девушка) в год кого родились?</T>
                        <FormInputTextAria
                            control={control}
                            label='Год животного бывшего партнера'
                            name='lastlove'
                        />
                        <Typography variant="subtitle1" sx={{ fontSize: 12 }} component="p">(если не знаете, то просто укажите год рождения)</Typography>

                        <hr/>

                        <T>Знак зодиака бывшего мужа или жены?</T>
                        <FormInputTextAria
                            control={control}
                            name='lastzodiak'
                            label="Знак зодиака бывшего партнера"
                        />
                        <Typography variant="subtitle1" sx={{ fontSize: 12 }} component="p">(если не знаете, то просто число и месяц даты рождения)</Typography>
                    </>
                )
            case 21:
                return (
                    <>
                        <Alert severity="info" sx={{
                            margin: '20px 0 0 0',
                            textAlign: 'left'
                        }}>
                            Почти всё... Чтобы получить доступ в&nbsp;<strong>бюро счастливых знакомств</strong>&nbsp;&mdash; Вам необходимо закончить регистрацию и&nbsp;подтвердить Email.
                        </Alert>
                        <T>Введите email:</T>
                        <FormInputUniversalText
                            type='email'
                            name='email'
                            focused
                            control={control}
                            rules={{ min: 3, validate: (value: any)  => (value === '') }}
                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        />
                        <T>Введите пароль:</T>
                        <FormInputUniversalText
                            type='password'
                            name='pass'
                            error={!!errors.passwordConfirm?.type}
                            rules={{ min: 6}}
                            control={control}
                            focused
                            className={`form-control ${errors.pass ? 'is-invalid' : ''}`}
                        />
                        <T>Введите пароль повторно:</T>
                        <FormInputUniversalText
                            type='password'
                            name='passwordConfirm'
                            error={!!errors.passwordConfirm?.type}
                            rules={{ min: 6, validate: (value: any) => (value === password.current) }}
                            control={control}
                            focused
                            className={`form-control ${errors.passwordConfirm ? 'is-invalid' : ''}`}
                        />
                        {/*<div
                            className="invalid-feedback"
                            style={
                                (!errors.passwordConfirm?.type)
                                ? {display: "none"}
                                : {}
                            }
                        >{errors.passwordConfirm?.type ? 'Пароли не совпадают' : ''}</div>
                        */}
                        {/*(errors.passwordConfirm?.type) ?
                        <Alert severity="error" sx={{
                            margin: '20px 0 0 0',
                            textAlign: 'left'
                        }}>
                            Пароли не совпадают
                        </Alert>
                        : '' */}
                        {(!handleValidation(false))?
                            <Alert severity="warning" sx={{
                                margin: '20px 0 0 0',
                                textAlign: 'left'
                            }}>
                                {handleValidation(true)}
                            </Alert>
                        : null}
                    </>
                )
            case 22:
                return (
                    <>
                        <T>Ваша анкета находится на модерации, в ближайшее время просим связаться с&nbsp;<a href={'https://vk.com/svastro'} target={'_blank'} rel={'nofollow'}>администрацией сообщества</a> для скорейшего прохождения модерации Вашей анкеты.</T>
                    </>
                )
            case 23:
                return (
                    <>

                        <T>Ваша анкета находится на модерации, в ближайшее время просим связаться с&nbsp;<a href={'https://vk.com/svastro'} target={'_blank'} rel={'nofollow'}>администрацией сообщества</a> для скорейшего прохождения модерации Вашей анкеты.</T>
                        <Link to={'/login'}>Войти</Link>
                    </>
                )
            default:
                return <h2>Что то не работает...сообщите пожалуйста как это случилось администрации</h2>
        }
    }


    return(
        <Box ref={quizRef} sx={{textAlign: 'center'}}>
            <Form onSubmit={handleSubmit(onSubmit)} >
                <T sx={{ color: purple[600], fontSize: '1.75rem', lineHeight: '1.15' }}>Заявка на счастливую личную жизнь</T>
                <T>
                    Шаг <span style={{ color: red[800] }}>{value+1} </span>
                    {/*из  <span style={{ color: red[800] }}> 24</span>*/}
                </T>
                {renderForm()}
                <p>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={() => dispatch(decrement())}
                        disabled={value === 0}
                        sx={{
                            marginRight: '5%',
                            bgcolor: purple[600],
                            '&:hover': {
                                bgcolor: purple[500],
                            }
                    }}
                    >Назад</Button>

                    <Button
                        type="submit"
                        variant="contained"
                        onClick={() => dispatch(increment())}
                        disabled={value === 23}
                        sx={{
                            marginLeft: '5%',
                            bgcolor: purple[600],
                            '&:hover': {
                                bgcolor: purple[500],
                            }
                    }}
                    >Вперед</Button>
                </p>
            </Form>
        </Box>
    )
}



export default forwardRef((props, ref) => <Quiz quizRef={ref} />);
