import { Box,Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import * as React from "react";
import AliceCarousel from "react-alice-carousel";
import { Data } from '../../types/data'
import T from "../quiz/OnlyText";
import 'react-alice-carousel/lib/alice-carousel.css';
import Carousel from "../forAll/Carousel";
import regionCodes from "../forAll/regions";
import {QuizType} from "../../types/quiz";
//import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TextureIcon from '@mui/icons-material/Texture';
import '../../userlist.css';
import {useSubmitDataMutation} from "../../services/goroskop";

import VpnKeyIcon from '@mui/icons-material/VpnKey';
import {getCookie, setCookie} from "../../functions/Cookie";
import { useSnackbar } from "notistack"
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React
    
    .forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const User: React.FC<{userData: Data}> = ({userData}) => {

    const [
        user = userData,
        setUser
    ] = React.useState<Data>();

    const canUseCoins = (getCookie('use_coins') === '1' ? true : false);

    const slider = userData.images.split(',').map(item => {
        let src = '/images/noImg.jpg';
        if(item == 'hidden'){
            switch(userData.gender){
                case 'М':
                    src = '/images/hide-man-photo.jpg';
                    break;
                case 'Ж':
                    src = '/images/hide-woman-photo.jpg';
                    break;
            }
        }else{
            if(item && item.length > 0){
                src = `/upload/${item}`;
            } else{
                src = '/images/noImg.jpg';
            }
        }
        return (
            <img
                key={item}
                src={src}
                style={{ maxWidth: '45%', marginLeft: '27.5%' }}
                alt={item}
                loading="lazy"
            />
        )
    })

    const [open, setOpen] = React.useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [submitData, { }] = useSubmitDataMutation()
    // const [user, setUser] = React.useState<Data>();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const useCoin = (userId:string) => {
        if(userId){
            let fD = new FormData();
            fD.append('id', userId);
            submitData({name: 'use_coin.php', data: fD})
                .then(response => {
                    //console.log('response',response)
                    // @ts-ignore
                    let res = response.data;
                    if (res && typeof (res.status) === 'boolean') {
                        if (res.msg && res.msg.length > 0) {
                            if(res.status === true && res.user){
                                setUser(res.user);
                            }
                            enqueueSnackbar(res.msg, {variant: (res.status === true ? 'success' : 'error')});
                        } else {
                            enqueueSnackbar('Ошибка открытия профиля №2', {variant: 'error'});
                        }
                    }
                    if (typeof (res.coins) !== "undefined" && res.coins >= 0) {
                        setCookie('coins', (res.coins).toString(), 7);
                    }
                    handleClose();
                })
        }else{
            enqueueSnackbar('Ошибка открытия профиля №1', {variant: 'error'});
        }
    }

    const doWorkCoin = (userId: string) => {
        if(typeof(userId) === 'string') {
            useCoin(userId)
        } else {
            handleClose()
        }
    }

    const alertDialogSlide = (userId: string, compatible: number, coins: number, flagShowBtn: boolean) => {
        console.log({
            userId: userId,
            flagShowBtn: flagShowBtn,
        });
        if(flagShowBtn) {
            let openAccess = flagShowBtn && (coins > 0);
            return (
                <React.Fragment>
                    <T sx={{textAlign: 'center'}}>
                        <Button style={{
                            borderRadius: 35,
                            backgroundColor: (openAccess ? '#673ab7' : '#d1c4e9'),
                            padding: "6px 18px",
                            fontSize: "18px"
                        }} variant="contained" onClick={handleClickOpen}>
                            {'Открыть профиль'}
                            <VpnKeyIcon style={{
                                margin: '-4px 0 0 5px'
                            }}/>
                        </Button>
                    </T>
                    <Dialog
                        open={open}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{openAccess
                            ? 'Вы уверены что хотите открыть этот профиль?'
                            : 'Вашего тарифа недостаточно чтобы использовать ключ на этом профиле'
                        }</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                {openAccess
                                    ? 'Вы уверены, что хотите открыть профиль совместимостью ' + compatible + '%? На это действие нужно использовать 1 ключ, Вам доступно: ' + coins + '.'
                                    : 'Обратитесь к нашему менеджеру в бюро счастливых знакомств!'
                                }
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>{openAccess ? 'Отмена' : 'Закрыть'}</Button>
                            {
                                openAccess
                                    ? <Button onClick={() => doWorkCoin(userId)}>Открыть</Button>
                                    : null
                            }
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            );
        }
    }

    const printUserInfo = (fieldName: string, fieldData: string | number | null, link: boolean, except?: string) => {
        if(fieldData == 'hidden'){
            return (<Typography
                variant="subtitle1"
                component="p"
                sx={{ marginTop: 3, marginButtom: 3 }}
                className='hiddenField'>
                    <b>{fieldName}:</b> <TextureIcon />
            </Typography>);
        }else{
            if(except && fieldData == except){
                return null;
            }
        }

        if(!link) {
            return (<Typography
                variant="subtitle1"
                component="p"
                sx={{ marginTop: 3, marginButtom: 3 }}>
                    <b>{fieldName}:</b> {fieldData}
            </Typography>);
        }else{
            if(fieldData) {
                let mylink = fieldData.toString();
                return (<Typography
                    variant="subtitle1"
                    component="p"
                    sx={{ marginTop: 3, marginButtom: 3 }}>
                        <b>{fieldName}:</b> <a href={mylink} target='_blank' rel={'nofollow noreferrer'}>{fieldData}</a></
                    Typography>);
            }
        }
        return null;
    }
    const printUseCoin = (userId:string, compatible:number, hide_btn: boolean | undefined, isnew: boolean | undefined) => {
        let coins = getCookie('coins');
        let coins2 = 0;
        if(coins){
            coins2 = parseInt(coins);
        }
        let flagShowBtn = (hide_btn === false);
        if(!isnew) {
            return alertDialogSlide(
                userId,
                compatible,
                coins2,
                flagShowBtn
            );
        }else{
            return null;
        }
    }

    return(
        <>
            <Box sx={{

                '& > p': {
                    userSelect: 'none',
                    lineHeight: '1.4'
                },

            }}>
                <DialogTitle id="scroll-dialog-title">{user.firstname == 'hidden' ? 'Пользователь' : user.firstname}</DialogTitle>
                <Carousel items={slider} />
                {canUseCoins ? printUseCoin(user.id, user.compatible, user.hide_btn, user.isnew) : null}
                {user.compatible || user.compatible >= 0 ? printUserInfo('Ваш уровень астрологической совместимости',user.compatible+'%', false) : null}
                {printUserInfo('Внешний код', (user.user_OutNum && user.user_OutNum != '' ? user.user_OutNum : 'id'+user.id), false)}
                {user.user_InNum ? printUserInfo('Внутренний код', user.user_InNum, false) : null}
                {user.city ? printUserInfo('Регион', user.city, false) : null}
                {user.birthday ? printUserInfo('Дата рождения',
                    //@ts-ignore
                    user.birthday,
                    false) : null}
                {user.birthyear ? printUserInfo('Знак по восточной астрологии', user.birthyear, false) : null}
                {user.zodiak ? printUserInfo('Знак по западной астрологии', user.zodiak, false) : null}
                {user.langlove ? printUserInfo('Основной язык любви', user.langlove, false) : null}
                {user.langlove2 ? printUserInfo('Второстепенный язык любви', user.langlove2, false) : null}
                {user.phone ? printUserInfo('Номер телефона', user.phone, false) : null}
                {user.vk ? printUserInfo('Ссылка на страницу вконтакте', user.vk, true) : null}
                {user.familystatus ? printUserInfo('Семейное положение', user.familystatus, false) : null}
                {user.gender ? printUserInfo('Пол', user.gender, false) : null}
                {user.targetsearch ? printUserInfo('Вид поиска партнера', user.targetsearch, false) : null}
                {user.height ? printUserInfo('Рост', user.height, false) : null}
                {user.smoke ? printUserInfo('С.о.о.к.к. (сведения об отношении к курению)', user.smoke, false) : null}
                {user.children ? printUserInfo('С.о.н.д. (сведения о наличии детей)', user.children, false, 'Не указано') : null}
                {user.registermonth_new ? printUserInfo('Дата вступления в базу', user.registermonth_new, false) : null}
                {user.about ? printUserInfo('Информация о себе', user.about, false) : null}
            </Box>
        </>
    )
}

export default User
