import { useSnackbar } from "notistack"
import { FC, useEffect, useState } from "react";
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Button, Card, CardActionArea, Dialog, DialogActions, DialogContent, Modal } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import {useGetApiQuery, useSubmitDataMutation} from "../../services/goroskop";
import User from "./user";
import { Data } from "../../types/data";
import { useParams } from "react-router-dom";
import T from "../quiz/OnlyText";
import Header from "../forAll/header";



const Information: FC = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const snippetData = useGetApiQuery('info.php')
    const printInformation = (data: any) => {
        if(data && data[0].status){
            let info = data[0].data;
            var printInfo = {
                first: [],
                east: [],
                west: [],
                last: [],
            }
            let accessPrint = false;
            if(info.first){
                printInfo.first = info.first.split(/\r\n|\r|\n/);
            }else{
                printInfo.first = [];
            }
            if(info.east){
                printInfo.east = info.east.split(/\r\n|\r|\n/);
                if(printInfo.east.length > 0){
                    accessPrint = true;
                }
            }else{
                printInfo.east = [];
            }
            if(info.west){
                printInfo.west = info.west.split(/\r\n|\r|\n/);
                if(printInfo.west.length > 0){
                    accessPrint = true;
                }
            }else{
                printInfo.west = [];
            }
            if(info.last){
                printInfo.last = info.last.split(/\r\n|\r|\n/);
            }else{
                printInfo.last = [];
            }
            //console.warn('print info',printInfo)
            if(accessPrint) {
                return (<Typography>
                    <T>{(printInfo.first).map((item: any) => {
                        return <T>{item}</T>;
                    })}</T>
                    <hr/>
                    <T>{(printInfo.east).map((item: any) => {
                        return <T>{item}</T>;
                    })}</T>
                    <hr/>
                    <T>{(printInfo.west).map((item: any) => {
                        return <T>{item}</T>;
                    })}</T>
                    <hr/>
                    <T>{(printInfo.last).map((item: any) => {
                        return <T>{item}</T>;
                    })}</T>
                </Typography>)
            }else{
                return (<Typography>Данный раздел в разработке. Скоро здесь появятся рекомендации специально подобранные для Вас!</Typography>)
            }
        }else{
            enqueueSnackbar('Ошибка', {
                variant: 'error',
            })
        }
    }

    return (
        <>
            <Box sx={{ margin: '30px 30px 180px 30px' }}>
                {snippetData.isLoading && <h1>Загрузка, подождите пожалуйста</h1>}
                {snippetData.isSuccess && printInformation(snippetData.data)}
            </Box>

        </>
    )
}

export default Information
