export function translateEast (eng: string) {
    switch (eng){
        case 'chicken': return 'Петух';    break;
        case 'dog':     return 'Собака';   break;
        case 'dragon':  return 'Дракон';   break;
        case 'horse':   return 'Лошадь';   break;
        case 'monkey':  return 'Обезьяна'; break;
        case 'ox':      return 'Бык';      break;
        case 'pig':     return 'Кабан';    break;
        case 'rabbit':  return 'Кролик';   break;
        case 'rat':     return 'Крыса';    break;
        case 'sheep':   return 'Коза';     break;
        case 'snake':   return 'Змея';     break;
        case 'tiger':   return 'Тигр';     break;
        default:        return '-';        break;
    }
}

export function translateWest (eng: string) {
    switch (eng){
        case 'aries':       return 'Овен';      break;
        case 'taurus':      return 'Телец';     break;
        case 'gemini':      return 'Близнецы';  break;
        case 'cancer':      return 'Рак';       break;
        case 'leo':         return 'Лев';       break;
        case 'virgo':       return 'Дева';      break;
        case 'libra':       return 'Весы';      break;
        case 'scorpio':     return 'Скорпион';  break;
        case 'sagittarius': return 'Стрелец';   break;
        case 'capricorn':   return 'Козерог';   break;
        case 'aquarius':    return 'Водолей';   break;
        case 'pisces':      return 'Рыбы';      break;
        default:            return '-';         break;
    }
}