import React, { useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import { Controller, useForm } from 'react-hook-form';
import {FormInputProps} from "../../../types/formInputProps";
import T from "../../quiz/OnlyText";
import Stack from '@mui/material/Stack';

const agesExpectArr = [3,6,9,15];

function valuetext(value: number) {
    return `${value} лет`;
}

const FormInputRangeSlider = ({ name, control, labelString, rules, className,
  minRange,
  maxRange,
  minValue,
  maxValue,
  stepRange,
  yearStep
}: Omit<FormInputProps, 'label'>) => {
    //const [value, setValue] = React.useState<number[]>([20, 55]);
    const [value, setValue] = useState<number[]>([minValue, maxValue]);

    //@ts-ignore
    if(minRange === 'undefined'){
        minRange = 18
    }
    //@ts-ignore
    if(maxRange === 'undefined'){
        maxRange = 100
    }

    let age: number, ageStorage: string | null;
    ageStorage = localStorage.getItem('age');
    age = 0;
    if(ageStorage) {
        age = parseInt(ageStorage);
    }
    function declination(number: number) {
        let titles = [' год', ' года', ' лет'];
        let cases = [2, 0, 1, 1, 1, 2];
        return titles[ (number%100>4 && number%100<20)? 2:cases[(number%10<5)?number%10:5] ];
    }

    const getGodaLet = (count: number) => {
        const title = declination(count);
        const age = count + title;
        return age;
    }

    const marks = [
        {value: age, label: 'Вам ' + (age > 0) ? ('Вам ' + getGodaLet(age)) : '-'},
    ];

    var ea:number[] = []; // except Ages
    if(yearStep){
        if(age >= 0){
            agesExpectArr.forEach((ageEx) => {
                ea.push(age + ageEx);
                if(age > ageEx) ea.push(age - ageEx);
            })
        }
    }


    const handleChange = (event: Event, newValue: number | number[]) => {
        if(newValue){
            try {
                let resetting = false;
                let tmp0 = minRange;
                let tmp1 = maxRange;
                //@ts-ignore
                let id0 = ea.indexOf(newValue[0]);
                if(id0 >= 0){
                    //@ts-ignore
                    //console.log({'newValue[0]': newValue[0], minValue: minValue})
                    //@ts-ignore
                    if(newValue[0] > minValue) {
                        //@ts-ignore
                        tmp0 = newValue[0] + 1;
                        //@ts-ignore
                    }else if(newValue[0] < minValue) {
                        //@ts-ignore
                        tmp0 = newValue[0] - 1;
                    }
                    //console.log('['+id0+']found from '+ newValue[0] +' -> '+ tmp0);
                    //@ts-ignore
                    if(tmp0 >= minRange) {
                        //@ts-ignore
                        newValue[0] = tmp0;
                    }else{
                        //@ts-ignore
                        newValue[0] = minRange;
                    }
                    resetting = true;
                }
                //@ts-ignore
                if(newValue[0] > 0) { localStorage.setItem('ageFrom',newValue[0].toString()); }

                //@ts-ignore
                let id1 = ea.indexOf(newValue[1]);
                if(id1 >= 0){
                    //@ts-ignore
                    //console.log({'newValue[1]': newValue[1], maxRange: maxValue})
                    //@ts-ignore
                    if(newValue[1] > maxValue) {
                        //@ts-ignore
                        tmp1 = newValue[1] + 1;
                        //@ts-ignore
                    }else if(newValue[0] < maxValue) {
                        //@ts-ignore
                        tmp1 = newValue[1] - 1;
                    }
                    //console.log('['+id1+']found from '+ newValue[1] +' -> '+ tmp1);
                    //@ts-ignore
                    if(tmp1 <= maxRange) {
                        //@ts-ignore
                        newValue[1] = tmp1;
                    }else{
                        //@ts-ignore
                        newValue[1] = maxRange;
                    }
                    resetting = true;
                }
                //@ts-ignore
                if(newValue[1] > 0) { localStorage.setItem('ageUntil',newValue[1].toString()); }

                if(resetting){
                    // console.log('reset newValue', newValue)
                    //@ts-ignore
                    setValue(newValue);
                }
            }catch (e){}
        }
        setValue(newValue as number[]);
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={[minValue, maxValue]}
            rules={{
                ...rules
            }}
            render={(props) => (
                <Stack>
                    <T>{labelString}</T>
                    <T sx={{
                        padding: '0 42px 0 42px'
                    }}>
                    <Slider
                        //getAriaLabel={() => 'Bla bla bla'}
                        value={value}
                        onChange={handleChange}
                        valueLabelDisplay="on"
                        getAriaValueText={valuetext}
                        {...props}
                        className={className}
                        min={minRange}
                        max={maxRange}
                        step={stepRange}
                        color="secondary"
                        style={{
                            margin: '15px 0 20px 0'
                        }}
                        marks={marks}
                    />
                    </T>
                </Stack>
            )}
        />
    );
}

export default FormInputRangeSlider;
