import React, { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { Card, CardActionArea, CardContent, CardMedia, TextField, Typography } from "@mui/material"
import { useSnackbar } from "notistack"
import { Controller, SubmitHandler, useForm, useFormState } from "react-hook-form"
import ReactInputMask from "react-input-mask"
import { useSubmitDataWithRerenderMutation} from "../../../services/goroskop"
import { QuizType } from "../../../types/quiz"
import FormInputDate from "../../quiz/form-component/FormInputDate"
import FormInputDropdown from "../../quiz/form-component/FormInputDropDown"
import FormInputRadioButtonAdmin from "../../quiz/form-component/FormInputRadioButtonsAdmin"
import FormInputReactMask from "../../quiz/form-component/FormInputReactMask"
import FormInputText from "../../quiz/form-component/FormInputText"
import FormInputTextAria from "../../quiz/form-component/FormInputTextArea"
import LangLoveList from "../../quiz/langLoveList"
import T from "../../quiz/OnlyText"
import PopUpImage from "../../quiz/PopUpImage"
import ZodiakList from "../../quiz/zodiakList";
import { styled } from "@mui/system";
import restrictRole from "../../../functions/restrictRole";
import Carousel from "../../forAll/Carousel";
import {getCookie} from "../../../functions/Cookie";
import FormInputLangLoves from "../../quiz/form-component/FormInputLangLoves";
import FormInputLangLoves2 from "../../quiz/form-component/FormInputLangLoves2";
import genderList from "../../forAll/genders";
const imgInputIds = ['o_img1', 'o_img2', 'o_img3', 'o_img4'];
import { Dayjs } from 'dayjs';
import DialogAddCoins from './dialogAddCoins'

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
interface ImmageArr {
    o_img1: File | string | null;
    o_img2: File | string | null;
    o_img3: File | string | null;
    o_img4: File | string | null
}

const imgFields:any[] = ['o_img1','o_img2','o_img3','o_img4'];
interface Props {
    defaultValues: QuizType
}

const Input = styled('input')({
    display: 'none',
});

const Form = styled('form')({
    // display: 'none',
    textAlign: 'left',
    margin: '0 auto 0 auto',
    width: '100%',
    maxWidth: '600px',
    '& > div': {
        border: '1px solid rgb(80, 61, 122)'
    },
    '& > div > label.MuiFormLabel-filled': {
        display: 'none'
    },
    '& > div > label.css-u9osun': {
        display: 'none'
    },
    '& > div > label.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root': {
        display: 'none'
    },
    // marginLeft: '25%',
});

interface userData {
    id: string | number | undefined,
    firstname: string | null | undefined,
    vk: string | null | undefined,
    email: string | null | undefined,
    phone: string | null | undefined,
}

const FormAdmin: FC<Props> = ({defaultValues}) => {
    var lastUpdate = 0;

    const [submitData, { data, error }] = useSubmitDataWithRerenderMutation()
    if(defaultValues.images == null){
        defaultValues.images = '';
    }
    const imgArray:string[] = defaultValues.images.split(',')

    const [imageNameArr, setimageNameArr] = useState<string[]>([])
    const [imageArr, setImageArr] = useState<ImmageArr>({
        o_img1: imgArray[0],
        o_img2: imgArray[1],
        o_img3: imgArray[2],
        o_img4: imgArray[3]
    })
    const [usersInfo, setUsersInfo] = useState<userData[]>([]); // for doublesCheck
    const [open, setOpen] = useState(false); // for dialog
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const printDoubleWarning = () => {
        return (
            <React.Fragment>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Обнаружены дубли"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>id</TableCell>
                                            <TableCell align="right">Имя</TableCell>
                                            <TableCell align="right">VK</TableCell>
                                            <TableCell align="right">Email</TableCell>
                                            <TableCell align="right">Телефон</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {usersInfo.map((row) => (
                                            <TableRow
                                                key={row.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <a href={`../profile/${row.id}`} target='_blank'>{row.id}</a>
                                                    {
                                                        //<Link to={`../profile/${modId}`}><EditIcon /></Link>
                                                    }
                                                </TableCell>
                                                <TableCell align="right">{row.firstname}</TableCell>
                                                <TableCell align="right">{row.vk}</TableCell>
                                                <TableCell align="right">{row.email}</TableCell>
                                                <TableCell align="right">{row.phone}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} autoFocus>
                            ОК
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    const slider = Object.values(imageArr).map(item => {
        return (
            <img
                key={item}
                src={item && item.length > 0 ? `/upload/${item}` : '/images/noImg.jpg'}
                style={{
                    margin: '0 auto 0 auto',
                    maxWidth: '600px'
            //         maxWidth: '45%',
            //         // marginLeft: '27.5%'
            }}
                alt={item}
                loading="lazy"
            />
        )
    })

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { register, handleSubmit, formState: { errors }, control, setValue, reset } = useForm<QuizType>({
        defaultValues: defaultValues
    });

    useEffect(() => {
        try{
            // @ts-ignore
            if (typeof(data) !== "undefined" && data.files) {
                // @ts-ignore
                setImageArr(data.files);

                // @ts-ignore
                imgFields.forEach((field) => {
                    // @ts-ignore
                    imageNameArr[Object.keys(data.files).indexOf(field)] = data.files[field] ? data.files[field] : 'null'
                })
            }else{
                // При первой загрузке страницы не учитываются имена имеющихся файлов, фикс в этом блоке:
                let tmpArr:string[] = [];
                let setImgFlag = false;
                if(imageNameArr.length == 0 && imageArr){
                    if(imageArr.o_img1 != '' && typeof(imageArr.o_img1) === 'string'){
                        tmpArr[0] = imageArr.o_img1;
                        setImgFlag = true;
                    }
                    if(imageArr.o_img2 != '' && typeof(imageArr.o_img2) === 'string'){
                        tmpArr[1] = imageArr.o_img2;
                        setImgFlag = true;
                    }
                    if(imageArr.o_img3 != '' && typeof(imageArr.o_img3) === 'string'){
                        tmpArr[2] = imageArr.o_img3;
                        setImgFlag = true;
                    }
                    if(imageArr.o_img4 != '' && typeof(imageArr.o_img4) === 'string'){
                        tmpArr[3] = imageArr.o_img4;
                        setImgFlag = true;
                    }
                }
                if(setImgFlag){
                    setimageNameArr(tmpArr);
                }
            }
        }catch (e){}
    });

    const onSubmit: SubmitHandler<QuizType> = data => {
        // enqueueSnackbar('[static] Данные обновлены', {
        //     variant: 'success',
        // });

        let newData = data

        newData.images = Object.values(imageArr).join(',')
        newData.status = data.status ?? restrictRole()

        let fd = new FormData()

        fd.append('data', JSON.stringify(newData))
        //console.log(fd.get('data'));

        submitData({ name: 'profile_save.php', data: fd }).then((answer:any) => {
            if(answer.data && answer.data[0]){
                enqueueSnackbar(
                    (answer.data[0].msg ? answer.data[0].msg : 'Ошибка передачи данных на сервер'),
                    {variant: answer.data[0].status ? 'success' : 'error'}
                )
                if(answer.data[0].doubles){
                    //console.log('doubles',answer.data[0].data);
                    setUsersInfo(answer.data[0].data)
                    handleClickOpen();
                }
            }
        })
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        let fd = new FormData()

        const file = e.target.files ? e.target.files[0] : 'null'
        const fileName = e.target.files ? e.target.files[0]?.name : 'null'
        const inputName = e.currentTarget.name

        // needed:
        imageNameArr[Object.keys(imageArr).indexOf(e.target.name)] = e.target.files ? e.target.files[0].name : 'null'

        // --------- поиск старой картнки -----------
        // Берем строку картинок пользователя, потом склеиваем, получаем массив имен картинок
        // и берем катинку из массива по индексу. Сам индекс берем из названия инпута, так же склеиваем,
        // переворачиваем и берем первый элемент массива и преобразуем в число, потом отнимаем единицу
        // все легко, не правда, ли?
        const lastNameImg = defaultValues.images.split(',')[Number(e.target.name.split('').reverse()[0]) - 1]

        let newString = defaultValues.images.split(',')
        newString[newString.indexOf(lastNameImg)] = e.target.files ? e.target.files[0]?.name : 'null'

        const imageObject = { ...imageArr, [inputName]: fileName }
        //const strOfImg = (Object.values(imageObject)).join(',')

        setValue(e.currentTarget.name as keyof QuizType, e.target.files ? e.target.files[0]?.name : 'null')
        //setValue('images', strOfImg)
        setValue('images', imageNameArr.join(','))

        fd.append(e.target.name, file)
        fd.append('user_id', defaultValues.id)
        //fd.append('images', strOfImg)
        fd.append('images', imageNameArr.join(','))

        submitData({ name: 'updatePhoto.php', data: fd })
            .finally(async () => {
                console.log('uploaded imageArr1', imageArr);
                //setImageArr({ ...imageArr, [inputName]: fileName })
                console.log('uploaded imageArr2', imageArr);
                enqueueSnackbar('Картинка изменена', {
                    variant: 'info',
                });
            })


    }

    const deleteImage = (nameImg: number) => {

        let fd = new FormData()

        fd.append('imgName', Object.values(imageArr)[nameImg])
        fd.append('user_id', defaultValues.id)

        submitData({ name: 'deleteFile.php', data: fd })

        setImageArr({ ...imageArr, [Object.keys(imageArr)[nameImg]]: null })
    }

    const role = getCookie('role');

    function showTargetSearch(){
        if(role == 'ok_admin') { // ok_mainModer
            return <>
                <T>Вид поиска</T>
                <FormInputRadioButtonAdmin
                    control={control}
                    name='targetsearch'
                    options={['Активный', 'Пассивный', 'Консультация', 'Встреча']}
                />
                <FormInputTextAria
                    style={{marginTop: '15px'}}
                    control={control}
                    label='Вид поиска'
                    name="targetsearchtext"
                />
            </>
        }
    }

    function declination(number: number) {
        let titles = [' год', ' года', ' лет'];
        let cases = [2, 0, 1, 1, 1, 2];
        return titles[ (number%100>4 && number%100<20)? 2:cases[(number%10<5)?number%10:5] ];
    }

    const getGodaLet = (count: number) => {
        const title = declination(count);
        const age = count + title;
        return age;
    }

    const getVozrast = (date: Dayjs | null, from: number, until: number) => {
        // return date; - 1971-07-27
        if(date) {
            let dd: string | number,
                mm: string | number,
                yyyy: string | number;

            if(typeof(date) === 'string'){
                //@ts-ignore
                let dateArrTmp = date.split(' ');
                let dateArr = dateArrTmp[0].split('-');
                if(dateArr.length == 3){
                    yyyy = dateArr[0];
                    mm = dateArr[1];
                    dd = dateArr[2];
                }
            }else{
                dd = (date.date());
                mm = (date.month()) + 1;
                yyyy = (date.year());
            }
            let today = new Date();
            //@ts-ignore
            let birthDate = new Date(yyyy, mm, dd);

            //console.log({dd: dd, mm: mm, yyyy: yyyy,});

            let nowYear = today.getFullYear();
            let pseudoYear = nowYear;
            let age = nowYear - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
                pseudoYear--;
            }
            return 'Клиенту ' + getGodaLet(age) +
                ', от ' + (pseudoYear - until) +
                ' до ' + (pseudoYear - from) + ' года';
        }
        return '';
    }

    /*
    const getVozrast = (date: Dayjs | null, from: number, until: number) => {
        // return date; - 1971-07-27
        if(date) {
            let dateArray = date.split('-');
            if(dateArray.length === 3){
                let key = 0,
                    dd: string | number,
                    mm: string | number,
                    yyyy: string | number;

                dateArray.forEach((el) => {
                    //@ts-ignore
                    dateArray[key] = parseInt(el);
                    switch(key){
                        case 0:{
                            yyyy = parseInt(dateArray[key]);
                        }break;
                        case 1:{
                            mm = parseInt(dateArray[key]);
                        }break;
                        case 2:{
                            dd = parseInt(dateArray[key]);
                        }break;
                    }
                    key++;
                });

                let today = new Date();
                //@ts-ignore
                let birthDate = new Date(yyyy,mm,dd);

                let nowYear = today.getFullYear();
                let pseudoYear = nowYear;
                let age = nowYear - birthDate.getFullYear();
                let m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                    pseudoYear--;
                }
                return 'Клиенту ' + getGodaLet(age) +
                    ', от ' + (pseudoYear - until) +
                    ' до ' + (pseudoYear - from) + ' года';
            }
        }
        return '';
    }
    */

    const resendEmail = (user_id: string) => {
        let userId = parseInt(user_id);
        if(userId > 0){
            let fD = new FormData();
            fD.append('id', user_id);
            submitData({ name: 'resend_email.php', data: fD })
        }
    }

    const printDate = (date: string | undefined) => {
        if(date) {
            let newDate = date.split('-');
            return newDate[2] + '.' + newDate[1] + '.' + newDate[0];
        }
        return '';
    }

    const printTariffEnded= () => {
        if(defaultValues.tariff != "0") {
            return (
                <>
                    <T> Дата окончания тарифа (удалить после тестирования):</T>
                    <FormInputDate control={control} label='Дата окончания тарифа' name="tariff_until" />
                </>
            )
        }
        return null;
    }
    const printTariffMonth = () => {
        if(defaultValues.tariff != "0") {
            if (defaultValues.tariff == "1") {
                return (
                    <>
                        <T>По какое число выводить профиль - отчетный период конкретного пользователя (применяется
                            только для супер-вип)</T>
                        <FormInputDate control={control} label="За какой месяц выводятся профили" name="tariff_month"/>
                    </>
                )
            } else {
                return(<T>Tariff_month: {defaultValues.tariff_month}</T>)
            }
        }
        return null;
    }

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <T>Имя и фамилия </T>
                <FormInputText
                    control={control} name='firstname' label="Имя и фамилия"
                    rules={{ required: true }}
                />

                <T>Контрольная дата контакта</T>
                <FormInputDate control={control} label='Выберите дату заполнения анкеты' name="next_contact_date" />

                <T>Email</T>
                <FormInputText control={control} label='Ваш Email' name="email" />
                <T>Почта подтверждена</T>
                <FormInputDropdown
                    control={control}
                    setValue={setValue}
                    name='active'
                    label="Выберите вариант"
                    options={[
                        {value: '0', label: 'Требует активации'},
                        {value: '1', label: 'Почта подтверждена'},
                    ]}
                />
                {role == 'ok_admin' ? <T sx={{textAlign: 'center', marginTop: '5px'}}><Button variant="outlined" size="small" onClick={() => resendEmail(defaultValues.id)}>Отправить код подтверждения на Email</Button></T> : null}

                {/*<T sx={{background: '#ffeedd', padding: '10px', border: '1px solid black', borderRadius: '5px'}}>
                    <T>Старый VIP (Если включен, то тарифы не работают, человек видит всех как супер-вип)</T>
                    <FormInputDropdown
                        name={'vip'}
                        control={control}
                        setValue={setValue}
                        label="Старый VIP"
                        options={[
                            { label: 'Нет', value: '0' },
                            { label: 'Да', value: '1' },
                        ]}
                    />
                </T>*/}

                <T sx={{background: '#ffdddd', padding: '10px', border: '1px solid black', borderRadius: '5px'}}>
                    <T>Текущий тариф (старый статус VIP: <b>{defaultValues.vip == '1' ? 'Да' : defaultValues.vip == '0' ? 'Нет' : 'Не известен'})</b>, до {printDate(defaultValues.tariff_until)}</T>
                    <FormInputDropdown
                        control={control}
                        setValue={setValue}
                        name='tariff'
                        label="Выберите тариф"
                        options={[
                            {value: '0', label: '- Стандартная учетная запись -'},
                            {value: '1', label: '[1 месяц] - Марафон '},
                            {value: '10', label: '[1 месяц] - Марафон лимитированный'},
                            {value: '11', label: '[1 месяц] - Визуал - только просмотр фотографий'},
                            {value: '12', label: '[1 год] - Визуал - только просмотр фотографий'},
                            {value: '2', label: '[1 месяц] - Дружественная-промо VIP - видимость как vip '},
                            {value: '4', label: '[1 год] - Дружественная-промо VIP - видимость как vip '},
                            {value: '3', label: '[1 месяц] - Супер VIP - РЕГИОН'},
                            {value: '5', label: '[1 год] - Супер VIP'},
                        ]}
                    />
                    {printTariffEnded()}
                    {printTariffMonth()}
                    {DialogAddCoins(defaultValues.tariff_coins, defaultValues.id)}
                </T>

                <T>Фото</T>
                <Carousel items={slider} />

                <T sx={{width: '100%', position: 'relative'}}>
                {
                    Object.values(imageArr).map((img, index) => {
                        /*console.log({
                            img: img,
                            index: index,
                        });*/
                        return (
                                <Card sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0" }} key={index} onClick={() => deleteImage(index)}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={
                                                (img && img !== null && img.length > 0 )
                                                    ? `/upload/${img}`
                                                    : '/images/noImg.jpg'
                                            }
                                            alt="photo"
                                        />
                                        <CardContent >
                                            <Typography gutterBottom variant="h5" component="div" >
                                                Delete
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                        )})
                }
                </T>

                <label htmlFor="contained-button-file1">
                    {imgInputIds.forEach(inputId => {})}
                    <Input {...register('o_img1')} onChange={handleChange} id="contained-button-file1" type="file" accept="image/jpg, image/jpeg" />
                    <Button
                        sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0", textAlign: 'center' }}
                        variant="contained" component="span">
                        Картинка 1
                    </Button>
                </label>
                <label htmlFor="contained-button-file2">
                    <Input {...register('o_img2')} onChange={handleChange} id="contained-button-file2" type="file" accept="image/jpg, image/jpeg" />
                    <Button
                        sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0", textAlign: 'center' }}
                        variant="contained" component="span">
                        Картинка 2
                    </Button>
                </label>
                <label htmlFor="contained-button-file3">
                    <Input {...register('o_img3')} onChange={handleChange} id="contained-button-file3" type="file" accept="image/jpg, image/jpeg" />
                    <Button
                        sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0", textAlign: 'center' }}
                        variant="contained" component="span">
                        Картинка 3
                    </Button>
                </label>
                <label htmlFor="contained-button-file4">
                    <Input {...register('o_img4')} onChange={handleChange} id="contained-button-file4" type="file" accept="image/jpg, image/jpeg" />
                    <Button
                        sx={{ width: "calc(25% - 5px)", display: 'inline-block', margin: "0 5px 0 0", textAlign: 'center' }}
                        variant="contained" component="span">
                        Картинка 4
                    </Button>
                </label>

                <T>В год какого животного вы родились?</T>
                <LangLoveList name="birthyear" control={control} setValue={setValue} />

                <T>Знак по западной астрологии</T>
                <ZodiakList name="zodiak" control={control} setValue={setValue} />

                <T>Ваше семейное положение</T>
                <FormInputRadioButtonAdmin
                    control={control} name='familystatus'
                    options={['Холост', 'Не замужем', 'Женат', 'Замужем']}
                />

                <T>Желаемый возраст партнера клиента<br/>
                   {getVozrast(defaultValues.birthd, defaultValues.ageFrom, defaultValues.ageUntil)}</T>
                <FormInputText
                    control={control}
                    name='ageFrom'
                    label="Возраст от"
                />
                <FormInputText
                    control={control}
                    name='ageUntil'
                    label="Возраст до"
                />

                <T>Введите ваш номер телефона</T>
                <FormInputReactMask
                    control={control}
                    name='phone'
                />

                <T>Введите ссылку на страницу Vkontakte</T>
                <FormInputText control={control} label='Ссылка вконтакте' name="vk" />

                <T>Коментарий</T>
                <FormInputTextAria control={control} label='Комментарий' name="comment" sx={{height: '400px'}}/>

                <T>Откуда узнали обо мне</T>
                <FormInputText control={control} label='Откуда узнали обо мне' name="source" />

                <T>Информация о клиенте</T>
                <FormInputTextAria
                    label="Информация о клиенте"
                    name="about"
                    control={control}
                />

                <T>Пожелания клиента</T>
                <FormInputTextAria
                    control={control}
                    name='helptext'
                    label="Пожелания клиента"
                />

                <T>Введите ваш рост</T>
                <FormInputText control={control} label='рост' name="height" />

                <T>Социальные сети</T>
                <FormInputText control={control} label='Ссылка Instagram' name="inst" sx={{ margin: '10px 0px '}}/>
                <FormInputText control={control} label='Ссылка Facebook' name="fb" sx={{ margin: '10px 0px ' }}  />
                <FormInputText control={control} label='Ссылка Одноклассники' name="ok" sx={{ margin: '10px 0px ' }} />

                <T>Дата вступления в базу</T>
                <FormInputDate control={control} label='Выберите дату заполнения анкеты' name="reg_date" />

                <T>Месяц вступления в базу (old)</T>
                {/* <Controller
                    name={'registermonth_new'}
                    control={control}
                    render={({ field }) => {
                        // console.log(new Date(field.value).toLocaleString('ru-RU', { month: 'long', year: 'numeric' }));
                        return (
                            <TextField
                                size="small"
                                onChange={field.onChange}
                                value={(field.value.trim() && new Date(field.value).toLocaleString('ru-RU', { month: 'long', year: 'numeric' })) ?? ' '}
                                fullWidth
                                label={'месяц вступления в базу'}
                                variant="outlined"

                            />
                        )
                    }}
                /> */}
                <FormInputText control={control} label='Месяц вступления в базу' name="registermonth" />

                <T>Дата Регистрации (new)</T>
                <FormInputText
                    control={control}
                    label='Дата регистрации'
                    name="registermonth_new" />

                <T>Курение?</T>
                <FormInputRadioButtonAdmin
                    control={control}
                    name='smoke'
                    options={['Да', 'Нет', 'Не указано']}
                />

                <T>Наличие детей</T>
                <FormInputRadioButtonAdmin
                    control={control}
                    name='children'
                    options={['Да', 'Нет', 'Не указано']}
                />

                <T>Дата рождения детей и их пол</T>
                <FormInputTextAria
                    control={control}
                    name='birthdaychild1'
                    label="День рождение 1 ребенка"
                />
                <FormInputTextAria
                    control={control}
                    name='birthdaychild2'
                    label="День рождение 2 ребенка"
                />
                <FormInputTextAria
                    control={control}
                    name='birthdaychild3'
                    label="День рождение 3 ребенка"
                />
                <FormInputTextAria
                    control={control}
                    name='birthdaychild4'
                    label="День рождение 4 ребенка"
                />

                {showTargetSearch()}

                <T>Финансовый отчет</T>
                <FormInputText control={control} label='Финансовый отчет' name="report" />

                <T>Пол</T>
                <FormInputDropdown
                    control={control}
                    setValue={setValue}
                    name='gender'
                    label="Выберите пол"
                    options={genderList}
                />

                <T>Введите ваш вес</T>
                <FormInputText control={control} label='вес' name="weight" />

                <T>Дата окончания отношений</T>
                <FormInputDate control={control} label='Дата окончания отношений' name="dateofend" />

                <T>Бывшие: в год какого животного родились?</T>
                <FormInputTextAria
                    control={control}
                    label='Год какого животного бывший(ая)'
                    name='lastlove'
                />

                <T>Бывшие: знак по западной астрологии</T>
                <FormInputTextAria
                    label="Знак зодиака бывшего"
                    control={control}
                    name='lastzodiak'
                />

                <T>Пароль</T>
                <FormInputText control={control} label='Пароль' name="pass" />


                <T>Внешний код</T>
                <FormInputText control={control} label='Код регион' name="user_OutNum" />

                <T>Внутренний код</T>
                <FormInputText control={control} label='Код регион' name="user_InNum" />

                <T>Регион</T>
                <PopUpImage name="region_code.jpg" alt="Код региона Россия" />
                <FormInputText control={control} label='Код регион' name="city" />

                <T>Источник - {defaultValues.source_type}</T>
                <FormInputDropdown
                    name={'source_type'}
                    control={control}
                    setValue={setValue}
                    label="Источник"
                    options={[
                        { value: 'Квиз' },
                        { value: 'Ручной' },
                    ]}
                />

                <T>Статус</T>
                <FormInputDropdown
                    name={'status'}
                    control={control}
                    setValue={setValue}
                    label="Статус"
                    options={[
                        { label: 'На модерации у админа', value: '10' },
                        { label: 'Общая база', value: '29' },
                        { label: 'Архив', value: '30' },
                        { label: 'Корзина', value: '32' },
                    ]}
                />

                <T>Дата рождения</T>
                <FormInputDate control={control} label='День рождения' name="birthday" />

                {/*<T>Основной язык любви</T>
                <FormInputRadioButtonAdmin
                    control={control} name='langlove'
                    options={['Одобрение','Время', 'Помощь', 'Подарки', ]}
                />

                <T>Второстепенный язык любви</T>
                <FormInputRadioButtonAdmin
                    control={control} name='langlove2'
                    options={['Одобрение', 'Прикосновения','Время', 'Помощь', 'Подарки', ]}
                />*/}

                <T>Основной язык любви</T>
                <FormInputLangLoves
                    control={control}
                    name='langlove'
                />

                <T>Второстепенный язык любви</T>
                <FormInputLangLoves2
                    control={control}
                    name='langlove2'
                    forceupdate={true}
                />

                <T>Префильтр</T>
                <FormInputTextAria
                    control={control}
                    name='prefilter'
                    label="Префильтр"
                />

                <hr />

                <Button type="submit" variant="contained">Сохранить</Button>
            </Form>
            {printDoubleWarning()}
        </>
    )
}

export default FormAdmin
