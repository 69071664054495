import { FC, useCallback, useEffect} from "react";
import { Button, Card, CardActionArea, CardContent, CardMedia, styled, TextField, Typography } from "@mui/material"
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack"
import {deleteAllCookie, getCookie} from "../../functions/Cookie";
import { useAppSelector } from "../../hooks/hooks";
import {useAuthAccountMutation, useGetApiQuery, useSubmitDataMutation} from "../../services/goroskop";
import EnhancedTable from "./constituents/Table";
//import EnhancedTable from "./constituents/Table";
import {Data} from "../../types/data";
import FormInputTextAria from "../quiz/form-component/FormInputTextArea"
import {LoginType} from "../../types/login";
import {SnippetType} from "../../types/snippet";
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import {QuizType} from "../../types/quiz";
import { translateEast, translateWest } from "../../translate/symbols";
interface requestDat {
    status?: string | number | boolean,
    text?: string | undefined | null,
    msg?: string | undefined | null
}

const eastSymbols = ['chicken', 'dog', 'dragon', 'horse', 'monkey', 'ox', 'pig', 'rabbit', 'rat', 'sheep', 'snake', 'tiger'];
const westSymbols = ['aries', 'taurus', 'gemini', 'cancer', 'leo', 'virgo', 'libra', 'scorpio', 'sagittarius', 'capricorn', 'aquarius', 'pisces'];

const Snippets: FC = () => {
    const { register, handleSubmit,  control} = useForm<SnippetType>();
    const [submitData, { error, data, isError, isSuccess }] = useSubmitDataMutation()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    //import { useParams } from "react-router-dom";
    let { type, target } = useParams();

    var singleZnak = false, newTarget:any[];
    if(target) {
        newTarget = target.split('-');
        if (target.indexOf('single-') !== -1) {
            singleZnak = true;
        } else {
            singleZnak = false;
        }
    }
    const getUrl = (doit = '') => {
        let url = '';
        if(type && target) {
            //console.log('type',type);
            //console.log('target',target);
            switch (type){
                case 'static':
                    // 'predislovie', 'posleslovie'
                    url = 'snippets.php?type='+target;
                    break
                default:
                    let typePart = (type ? 'type='+type : '');
                    if(typePart && newTarget[0] && newTarget[1]) {
                        url = 'snippets.php?' + typePart + '&from='+newTarget[0]+'&to='+newTarget[1];
                    }
                    break;
            }
        }else{
            //console.log({type: type});
            url = 'snippets.php?' + (type ? 'type='+type : '');
        }
        if(doit){
            url += '&do='+doit;
        }
        //console.log('url', {type: type, target: target, url: url})
        return url;
    }
    const urlTargetGet = getUrl('get');
    //const { data, error, isLoading, currentData } = useGetApiQuery(urlTargetGet)
    const snippetInfo = useGetApiQuery(urlTargetGet)
    //const { data, error, isLoading } = useGetApiQuery('line5_oo.php')

    const onSubmit: SubmitHandler<SnippetType> = subData => {
        //console.log('onSubmit');
        const fd = new FormData();
        let access = false;
        if(!singleZnak) {
            if (target && subData && subData.content) {
                access = true;
                fd.append('place', target); // target
            }
        }else{
            if(newTarget[1]) {
                switch (type) {
                    case 'east':
                        if (eastSymbols.includes(newTarget[1])) {
                            fd.append('place', newTarget[1]);
                            access = true;
                        }
                        break;
                    case 'west':
                        if (westSymbols.includes(newTarget[1])) {
                            fd.append('place', newTarget[1]);
                            access = true;
                        }
                        break;
                }
            }
        }
        if(access) {
            fd.append('content', subData.content)
            // fd.append('pass', subData.password)
            submitData({name: getUrl('set'), data: fd}).then((answer:any) => {
                if(answer.data && answer.data[0]){
                    enqueueSnackbar(
                        (answer.data[0].msg ? answer.data[0].msg : 'Ошибка передачи данных на сервер'),
                        {variant: answer.data[0].status ? 'success' : 'error'}
                    )
                }
            })
        }else{
            enqueueSnackbar('Ошибка передачи данных на сервер', {
                variant: 'error',
            })
        }
    };
    const printFormArea = (data:any) => {
        if(data[0].status){
            let labelName = 'Комментарий';
            let showArea = false;
            if(!singleZnak) {
                switch (target) {
                    case 'predislovie':
                        showArea = true;
                        labelName = 'Предисловие';
                        break;
                    case 'posleslovie':
                        showArea = true;
                        labelName = 'Послесловие';
                        break;
                }
            }else{
                if(target) {
                    switch (type) {
                        case 'east':
                            if (eastSymbols.includes(newTarget[1])) {
                                showArea = true;
                                labelName = translateEast(newTarget[1]);
                            }
                            break;
                        case 'west':
                            if (westSymbols.includes(newTarget[1])) {
                                showArea = true;
                                labelName = translateWest(newTarget[1]);
                            }
                            break;
                    }
                }
            }
            // console.log('154',{
            //     target: target,
            //     type:type,
            //     eastSymbols:eastSymbols,
            //     labelName:labelName,
            //     newTarget: newTarget
            // });
            if(showArea) {
                return (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h1>Текст для "{labelName}"</h1>
                        <FormInputTextAria
                            control={control}
                            label={labelName}
                            name="content"
                            sx={{height: '400px'}}
                            defaultValue={(data[0].text ? data[0].text : '')}
                        />
                        <Button type="submit" variant="contained" >Сохранить</Button>
                    </form>
                );
            }
        }else{
            if(data[0].msg){
                enqueueSnackbar(data[0].msg, {
                    variant: 'error',
                })
                return (<>{data[0].msg}</>);
            }else {
                enqueueSnackbar('Упс... Ошибка на сервере', {
                    variant: 'error',
                })
            }
        }
        return (<>Ошибка</>);
    }

    return(
        <>
            {snippetInfo.isError && <h1>Ошибка</h1>}
            {snippetInfo.isLoading && <h1>Загрузка, подождите пожалуйста</h1>}
            {snippetInfo.isSuccess && printFormArea(snippetInfo.data)}
        </>
    )
}



export default Snippets