import React, {useState} from "react";
import Popup from "reactjs-popup";
import Link from '@mui/material/Link';
import { Box, Button, Typography } from "@mui/material"

// https://react-popup.elazizi.com/react-modal

const AgreementPopUp = ({welcomeText}: {welcomeText?: string}) => {
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);

    return (
        <Typography component='div' className="agreementtext" style={{ marginTop: '15px', border: 0, fontSize: '14px' }}>
            {welcomeText}&nbsp;<Link variant="body2" href={"#"} onClick={() => setOpen(o => !o)}>обработку персональных данных </Link>
            <Popup open={open} closeOnDocumentClick onClose={closeModal} modal>
                <Typography component='div' className="modal">
                    <button className="close" onClick={closeModal}>&times;</button>
                    <h3>Соглашение на обработку персональных данных</h3>
                    <Typography component='p'>Предоставляя свои персональные данные Пользователь даёт согласие на&nbsp;обработку, хранение
                        и&nbsp;использование своих персональных данных на&nbsp;основании
                        ФЗ &#8470;&nbsp;152-ФЗ &laquo;О&nbsp;персональных
                        данных&raquo; от&nbsp;27.07.2006&nbsp;г.&nbsp;в&nbsp;следующих целях:</Typography>
                    <br/>
                    <Typography component='p'>Осуществление клиентской поддержки</Typography>
                    <Typography component='p'>Получения Пользователем информации о&nbsp;маркетинговых событиях</Typography>
                    <Typography component='p'>Проведения аудита и&nbsp;прочих внутренних исследований с&nbsp;целью повышения качества предоставляемых услуг.</Typography>
                    <Typography component='p'>Под персональными данными подразумевается любая информация личного характера, позволяющая установить личность Пользователя/Покупателя такая как:</Typography>
                    <br/>
                    <ul>
                        <li>Фамилия, Имя, Отчество</li>
                        <li>Дата рождения</li>
                        <li>Контактный телефон</li>
                        <li>Адрес электронной почты</li>
                        <li>Почтовый адрес</li>
                    </ul>
                    <Typography component='p'>Персональные данные Пользователей хранятся исключительно на&nbsp;электронных носителях
                        и&nbsp;обрабатываются с&nbsp;использованием автоматизированных систем, за&nbsp;исключением случаев,
                        когда неавтоматизированная обработка персональных данных необходима в&nbsp;связи с&nbsp;исполнением
                        требований законодательства.</Typography>
                    <br/>
                    <Typography component='p'>Компания обязуется не&nbsp;передавать полученные персональные данные третьим лицам, за&nbsp;исключением
                        следующих случаев:</Typography>
                    <br/>
                    <Typography component='p'>По&nbsp;запросам уполномоченных органов государственной власти&nbsp;РФ только по&nbsp;основаниям
                        и&nbsp;в&nbsp;порядке, установленным законодательством РФ</Typography>
                    <Typography component='p'>Стратегическим партнерам, которые работают с&nbsp;Компанией для предоставления продуктов и&nbsp;услуг,
                        или тем из&nbsp;них, которые помогают Компании реализовывать продукты и&nbsp;услуги потребителям.
                        Мы&nbsp;предоставляем третьим лицам минимальный объем персональных данных, необходимый только для
                        оказания требуемой услуги или проведения необходимой транзакции.</Typography>
                    <Typography component='p'>Компания оставляет за&nbsp;собой право вносить изменения в&nbsp;одностороннем порядке в&nbsp;настоящие
                        правила, при условии, что изменения не&nbsp;противоречат действующему законодательству РФ. Изменения
                        условий настоящих правил вступают в&nbsp;силу после их&nbsp;публикации на&nbsp;Сайте.</Typography>
                </Typography>
            </Popup>
        </Typography>
    );
}

export default AgreementPopUp