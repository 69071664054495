import Box from '@mui/material/Box';
import { blue } from '@mui/material/colors';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
// import SvgIcon from '@mui/material/SvgIcon';
// import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FC } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { changePath, p } from '../../../reducers/adminSlice';
import { useAppDispatch } from '../../../hooks/hooks';
import { Button, Grid } from '@mui/material';
import { deleteCookie, getCookie } from '../../../functions/Cookie';
import { auth, MainState } from '../../../reducers/mainSlice';

import MailIcon from '@mui/icons-material/Mail';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import SecurityIcon from '@mui/icons-material/Security';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import StorageIcon from '@mui/icons-material/Storage';
import StarsIcon from '@mui/icons-material/Stars';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import InventoryIcon from '@mui/icons-material/Inventory';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import BackupIcon from '@mui/icons-material/Backup';
import ConstructionIcon from '@mui/icons-material/Construction';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';


interface SidebarCells {
    name: string,
    href: string,
    icon: string,
    dist?: string,
    color?: "error" | "inherit" | "disabled" | "action" | "primary" | "secondary" | "info" | "success" | "warning" | undefined
}

interface IconProps {
    icon: string;
    className: string;
}

const getIcon = (
    iconName: string,
    iconColor: "error" | "inherit" | "disabled" | "action" | "primary" | "secondary" | "info" | "success" | "warning" | undefined
) => {
    switch (iconName){
        case 'moderation':          return (<SecurityIcon color={iconColor} />); break;
        case 'moderation_admin':    return (<AdminPanelSettingsIcon color={iconColor} />); break;
        case 'line1':               return (<LooksOneIcon color={iconColor} />); break;
        case 'line2':               return (<LooksTwoIcon color={iconColor} />); break;
        case 'line3':               return (<Looks3Icon color={iconColor} />); break;
        case 'line4':               return (<Looks4Icon color={iconColor} />); break;
        case 'line5':               return (<Looks5Icon color={iconColor} />); break;
        case 'in_process':          return (<EngineeringIcon color={iconColor} />); break;
        case 'clients_list':        return (<FolderSharedIcon color={iconColor} />); break;
        case 'clients_add':         return (<PersonAddAlt1Icon color={iconColor} />); break;
        case 'search_active':       return (<TwoWheelerIcon color={iconColor} />); break;
        case 'search_passive':      return (<AgricultureIcon color={iconColor} />); break;
        case 'search_all':          return (<StorageIcon color={iconColor} />); break;
        case 'favorites':           return (<StarsIcon color={iconColor} />); break;
        case 'text_cons':           return (<FormatColorTextIcon color={iconColor} />); break;
        case 'archive':             return (<InventoryIcon color={iconColor} />); break;
        case 'trash':               return (<DeleteSweepIcon color={iconColor} />); break;
        case 'clients_excel':       return (<PlaylistAddCheckCircleIcon color={iconColor} />); break;
        case 'clients_import':      return (<BackupIcon />); break;
        case 'mail_icon':           return (<MailIcon color={iconColor} />); break;
        case 'tickets':             return (<ConstructionIcon color={iconColor} />); break;
        case 'emojiemotions':       return (<EmojiEmotionsIcon color={iconColor} />); break;
        case 'inbox_icon':
        default:                    return '<InboxIcon />';break;
    }
};

interface menuItem{
    name: string,
    status: boolean
}

var activeMenu:string = 'moderation';
const ClippedDrawer: FC = () => {

    const dispatch = useAppDispatch()
    let navigate = useNavigate();

    const Logout = () => {
        deleteCookie('role');
        dispatch(auth({} as MainState))
        navigate('/', { replace: true });
    }

    var menuStatus:menuItem[] = [
        {name: "moderation", status: true},
        {name: "moderation_admin", status: false},
        {name: "line1", status: false},
        {name: "line2", status: false},
        {name: "line3", status: false},
        {name: "line4", status: false},
        {name: "line5", status: false},
        {name: "in_process", status: false},
        {name: "search_active", status: false},
        {name: "search_passive", status: false},
        {name: "search_all", status: false},
        {name: "clients_add", status: false},
        {name: "clients_list", status: false},
        {name: "emojiemotions", status: false},
        {name: "favorites", status: false},
        {name: "text_cons", status: false},
        {name: "archive", status: false},
        {name: "trash", status: false},
        {name: "clients_excel", status: false},
        {name: "clients_import", status: false},
        {name: "tickets", status: false},
    ];
    const toggleStatus = (menuId:string) => {
        if(activeMenu != menuId){
            activeMenu = menuId;
        }
        for (var i = 0; i < menuStatus.length; i++) {
            if (menuStatus[i].name == activeMenu) {
                menuStatus[i].status = true;
            }else{
                menuStatus[i].status = false;
            }
        }
        /*const newMenuStatus = menuStatus.map(i=>{
            const temp:menuItem = {
                name: i.name,
                status: (i.name !== menuId ? false : true)
            };
            return temp;
        })
        menuStatus = newMenuStatus;*/
        // console.log('new0',{to:to, index:index})
        // console.log('new',newMenuStatus)
        //console.log('menuStatus',menuStatus);
    }
    const getActiveStatus = (menuId:string) => {
        let statusResult = false;
        for (var i = 0; i < menuStatus.length; i++) {
            if (activeMenu == menuId) {
                statusResult = true;
                break;
            }
        }
        return statusResult;
    }

    const menuList = {
        moderation: {
            icon: 'moderation', color: 'error',
            name: 'Модерация', href: 'moderation', dist: 'moderation'
        },
        moderation_admin: {
            icon: 'moderation_admin', color: 'error',
            name: 'Модерация админа', href: 'moderation_admin', dist: 'moderation_admin'
        },
        line1: {
            icon: 'line1', color: 'primary',
            name: 'Линия 1', href: 'lineone', dist: 'moderation'
        },
        line2: {
            icon: 'line2', color: 'primary',
            name: 'Линия 2', href: 'linetwo', dist: 'lines' // lines
        },
        line3: {
            icon: 'line3', color: 'primary',
            name: 'Линия 3', href: 'linethree', dist: 'line3'
        },
        line4: {
            icon: 'line4', color: 'primary',
            name: 'Линия 4', href: 'linefour', dist: 'line4'
        },
        line5: {
            icon: 'line5', color: 'primary',
            name: 'Линия 5', href: 'linefive', dist: 'line5'
        },
        in_process: {
            icon: 'in_process', color: 'primary',
            name: 'На обработке', href: 'in_process', dist: 'cons'
        },
        search_active: {
            icon: 'search_active', color: 'primary',
            name: 'Активный поиск', href: 'search_active', dist: 'profiles'
        },
        search_passive: {
            icon: 'search_passive', color: 'primary',
            name: 'Пассивный поиск', href: 'search_passive', dist: 'profiles'
        },
        search_all: {
            icon: 'search_all', color: undefined,
            name: 'Общая база', href: 'search_all', dist: 'profiles'
        },
        clients_add: {
            icon: 'clients_add', color: 'primary',
            name: 'Добавить клиента', href: 'profile-add'
        },
        clients_list: {
            icon: 'clients_list', color: 'warning',
            name: 'Клиенты', href: 'clients'
        },
        minion: { // sort
            icon: 'emojiemotions', color: 'warning',
            name: 'Я клиент', href: '/admin/profile/513'
        },
        favorites: {
            icon: 'favorites', color: 'warning',
            name: 'Избранные', href: 'favorites', dist: 'get_fav_orders'
        },
        text_cons: {
            icon: 'text_cons', color: 'secondary',
            name: 'Настройки', href: 'config', dist: 'config'
        },
        archive: {
            icon: 'archive', color: undefined,
            name: 'Архив', href: 'archive', dist: 'archive'
        },
        trash: {
            icon: 'trash', color: undefined,
            name: 'Корзина', href: 'korzina', dist: 'trash'
        },
        clients_excel: {
            icon: 'clients_excel', color: 'success',
            name: 'Клиенты из Excel', href: 'clients_excel'
        },
        clients_import: {
            icon: 'clients_import',
            name: 'Импорт', href: 'import'
        },
        tickets: {
            icon: 'tickets', color: 'error',
            name: 'Написать разработчику', href: 'tickets'
        },
    }
    const returnSidebarCells = () => {
        switch (getCookie('role')) {
            case 'ok_admin':
                return ([
                        menuList.moderation,         // Модерация
                        menuList.line1,              // Линия 1
                        menuList.line2,              // Линия 2
                        menuList.line3,              // Линия 3
                        menuList.line4,              // Линия 4
                        menuList.line5,              // Линия 5
                        menuList.in_process,         // На обработке
                        menuList.minion,             // Я клиент
                        menuList.clients_list,       // Клиенты
                        menuList.clients_add,        // Добавить клиента
                        menuList.search_active,      // Активный поиск
                        menuList.search_passive,     // Пассивный поиск
                        menuList.search_all,         // Общая база
                        menuList.favorites,          // Избранные
                        menuList.text_cons,          // Текстовая конс
                        menuList.archive,            // Архив
                        menuList.trash,              // Корзина
                        menuList.clients_excel,      // Клиенты из Excel
                        menuList.clients_import,     // Импорт
                        menuList.tickets,            // Предложить доработку
                    ]);
                break;
            case 'consideration': // Руководитель отдела обслуживания, ассистент директора
                return ([
                    menuList.moderation,         // Модерация
                    menuList.moderation_admin,   // Модерация админа
                    menuList.line1,              // Линия 1
                    menuList.line2,              // Линия 2
                    menuList.line3,              // Линия 3
                    menuList.line4,              // Линия 4
                    menuList.clients_add,        // Добавить клиента
                    menuList.search_active,      // Активный поиск
                    menuList.search_passive,     // Пассивный поиск
                    menuList.search_all,         // Общая база
                    menuList.tickets,            // Предложить доработку
                ]);
                break;
            case 'ok_mainModer':
                return ([
                        menuList.moderation,         // Модерация
                        menuList.line2,              // Линия 2
                        menuList.in_process,         // На обработке
                        menuList.clients_add,        // Добавить клиента
                        menuList.archive,            // Архив
                        menuList.trash,              // Корзина
                        menuList.tickets,            // Предложить доработку
                    ]);
                break;
            case 'ok_mainDok':
                return ([
                        menuList.moderation,         // Модерация
                        menuList.line3,              // Линия 3
                        menuList.line4,              // Линия 4
                        menuList.line5,              // Линия 5
                        menuList.in_process,         // На обработке
                        menuList.clients_add,        // Добавить клиента
                        menuList.search_passive,     // Пассивный поиск
                        menuList.archive,            // Архив
                        menuList.trash,              // Корзина
                        menuList.tickets,            // Предложить доработку
                    ]);
                break;
            default:
                return ([
                        menuList.moderation,         // Модерация
                        menuList.clients_add,        // Добавить клиента
                        menuList.tickets,            // Предложить доработку
                    ]);
            break;
        }
    }

    const sidebarCells: SidebarCells[] = returnSidebarCells()

    const setDefaultMenu = () => {
        let target = location.pathname;
        sidebarCells.forEach((el)=>{
            if(target.indexOf('/admin/'+el.href) !== -1){
                activeMenu = el.icon;
            }
        })
    }
    setDefaultMenu();

    return (
        <Box sx={{ display: 'flex', maxWidth: '100%' }} >
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                        AstroDating
                    </Typography>
                    <Button 
                        sx={{margin: '0 0 0 auto', backgroundColor: 'red'}}
                        variant='contained'
                        onClick={Logout}>
                        Выйти
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: '170px',
                    maxWidth: '25%',
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: '170px', maxWidth: '25%', boxSizing: 'border-box' },
                }}>
                <Toolbar />
                <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
                        {sidebarCells.map((text, index) => {
                            return(
                                    <Link
                                        to={text.href}
                                        key={index}
                                        style={{ textDecoration: 'none', color: 'black' }}
                                        onClick={(e) => toggleStatus(text.icon)}
                                    >
                                        <ListItem 
                                            onClick={() => text.dist && dispatch(changePath({ path: text.dist, name: text.name}))}
                                            sx={{
                                                maxHeight: 'inherit',
                                                borderBottom: '1mm solid rgb(170, 50, 220, .6);',
                                                bgcolor: (getActiveStatus(text.icon) ? blue[100] : null)
                                        }}>
                                            
                                            <ListItemText primary={
                                                <>
                                                    <ListItemIcon sx={{ justifyContent: 'center', width: '100%' }}>
                                                        {/*<MailIcon width={'28px'} />*/}
                                                        {getIcon(text.icon, text.color)}
                                                    </ListItemIcon>
                                                    <Typography  component='p' style={{ textAlign: 'center', margin: 0}}>
                                                        {text.name}
                                                    </Typography>
                                                        
                                                </>} 
                                                sx={{ maxHeight: 'inherit'}}
                                            />
                                            
                                        </ListItem>
                                    </Link>
                            )
                            })}
                    <Divider />
                </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3, maxWidth: '100%' }}>
                <Toolbar />
                <Outlet />
            </Box>
        </Box>
    );
}

export default ClippedDrawer