import { Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, Select } from "@mui/material";
import { pink } from "@mui/material/colors";
import { height, width } from "@mui/system";
import React, {useEffect, useReducer, useState} from "react";
import { Controller } from "react-hook-form";
import { FormInputProps } from "../../../types/formInputProps";
import {SelectChangeEvent} from "@mui/material/Select";
import T from "../OnlyText";

interface CheBoxProps extends Omit<FormInputProps, 'label'> {

}

const FormInputLangLoves2: React.FC<CheBoxProps> = ({
    name,
    control,
    forceupdate
}) => {
    const allOptions = [
     /* "Одобрение"*/
        "Прикосновения",
        "Время",
        "Помощь",
        "Подарки",
    ];

    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const langLove = localStorage.getItem('prelanglove1')
    var lastLangLove = langLove;

    if(forceupdate) {
        var forceInterval = setInterval(function () {
            let newLangLove = localStorage.getItem('prelanglove1');
            //console.log({newLangLove:newLangLove, lastLangLove:lastLangLove})
            if(lastLangLove !== newLangLove) {
                clearInterval(forceInterval);
                forceUpdate();
            }
        }, 1000);
    }

    const getDisabled = (value: string) => {
        let status: boolean = false;
        let access: any[] = [];

        switch (langLove){
            case 'Одобрение':{  // Одобрение
                access = ["Прикосновения", "Время", "Помощь", "Подарки"]; // Прикосновения, Время, Помощь, Подарки
            }break;
            case 'Время':{  // Время
                access = ["Прикосновения", "Помощь", "Подарки"]; // Прикосновения, Помощь, Подарки
            }break;
            case 'Помощь':{  // Помощь
                access = ["Прикосновения", "Подарки"]; // Прикосновения, Подарки
            }break;
            case 'Подарки':{  // Подарки
                access = ["Прикосновения"]; // Прикосновения
            }break;
            case 'Прикосновения':
            default: {
                access = [];
            } break;
        }
        return !access.includes(value);
    }

    return (
        <Controller
            control={control}
            name={name}
            render={
                ({ field: { onChange, value } }) => (
                    <RadioGroup
                        row
                        aria-labelledby={`input-radio-${name}`}
                        name={"position" + name}
                        id={"input-radio-" + name}
                        defaultValue="top"
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        {allOptions.map(option => {
                            return (
                                <FormControlLabel
                                    key={option + name}
                                    value={option ?? ' '}
                                    sx={{
                                        border: ['1px', 'solid', 'rgb(103 38 255)'],
                                        color: 'white',
                                        borderRadius: '5px',
                                        backgroundColor: '#8768c8',
                                        margin: '5px',
                                        width: 'calc(50% - 18px)'
                                    }}
                                    label={option}
                                    labelPlacement="start"
                                    onChange={(e) => {
                                        onChange(e)
                                    }}
                                    control={<Radio checked={value === option} />}
                                    disabled={getDisabled(option)}
                                />
                            );
                        })}


                    </RadioGroup>

                )}

        />

    );
};

export default FormInputLangLoves2

