//import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuthAccountMutation } from '../services/goroskop';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { LoginType } from '../types/login';
import { useSnackbar } from 'notistack';
import Email from './quiz/Email';
import Pass from './quiz/Pass';
import React, { useEffect, useState } from 'react';
import { deleteAllCookie, setCookie } from '../functions/Cookie'; // TODO: kndev Передалать на токен авторизации
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import useWhoIs from '../hooks/useWhoIs';
import { auth, MainState } from '../reducers/mainSlice';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import AgreementPopUp from "./quiz/agreement";
import Copyright from "./quiz/copyright";
import Socialicons from "./quiz/socialicons";
//import Avatar from "@mui/material/Avatar";
import ReCAPTCHA from "react-google-recaptcha";
import '../recaptcha.css'


// function Copyright(props: any) {
//     return (
//         <Typography variant="body2" color="text.secondary" align="center" {...props}>
//             {'Copyright © '}
//             <Link color="inherit" href="https://svat-astrolog.com/">
//                 Svat-astrolog
//             </Link>{' '}
//             {new Date().getFullYear()}
//             {'.'}
//         </Typography>
//     );
// }

const theme = createTheme();

export default function Recovery() {
    const recaptchaKey = '6Lc5wVIpAAAAAETbVmCpoN36AfYpoEiyAjV47agy';
    const [captchaflag, setCaptchaflag] = useState(false);
    const recaptchaRef = React.useRef<ReCAPTCHA>();
    const onSubmitWithReCAPTCHA = () => {
        const tmpToken = recaptchaRef?.current?.getValue();
        if(tmpToken && tmpToken.length > 3) {
            setCaptchaflag(true);
        }else{
            setCaptchaflag(false);
        }
    }
    const [submitData, { error, data, isError, isSuccess }] = useAuthAccountMutation()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { register, handleSubmit,  control} = useForm<LoginType>();
    const dispatch = useAppDispatch()

    let navigate = useNavigate();

    const onSubmit: SubmitHandler<LoginType> = subData => {
        let recaptchaToken = recaptchaRef?.current?.getValue();
        if(recaptchaToken) {
            deleteAllCookie()
            const fd = new FormData();
            fd.append('email', subData.email)
            fd.append('token', recaptchaToken)
            submitData({ name: 'recovery.php', data: fd })
        }
    };

    useEffect(() => {
        if (!!data && isSuccess ) {

            if(data.status === true) {
                const whoIs = data.whoIs

                if (whoIs !== '[]') {

                    let authData: MainState = {
                        Account: data.Account,
                        role: data.whoIs,
                        easyRole: "unlogined",
                        token: data.token
                    }
                    //console.log('authData',authData);
                    //console.log('data',data);
                    if (whoIs === 'ok_admin'
                        || whoIs === 'ok_mainModer'
                        || whoIs === 'consideration'
                        || whoIs === 'ok_Moder'
                        || whoIs === 'ok_mainDok'
                        || whoIs === 'ok_dok'
                    ) {

                        //(whoIs !== 'ok_Moder' && whoIs !== 'ok_mainDok') ? setCookie('role', whoIs, 7) : setCookie('intModer', whoIs, 7)

                        setCookie('role', whoIs, 7)

                        authData.easyRole = "admin";
                        /*if(whoIs === 'ok_mainDok' || whoIs === 'ok_dok'){
                            navigate('../admin/linethree', {replace: true})
                        }else {*/
                            navigate('../admin/moderation', {replace: true})
                        //}
                    } else if (whoIs === 'ok_user') {
                        setCookie('role', whoIs, 7)
                        authData.easyRole = "user";
                        navigate(`../cabinet/${data.Account.id}`, {replace: true});
                    }
                    //setCookie('uid', data.Account.id, 7)
                    setCookie('token', authData.token, 7)
                    //setCookie('token', data.token, 7)
                    //console.log('authData',authData);
                    //console.log('authData token',data.token);
                    dispatch(auth(authData))
                    if(data.msg) {
                        enqueueSnackbar(data.msg, {
                            variant: 'success',
                        })
                    }

                } else {
                    enqueueSnackbar('Неправильный логин или пароль', {
                        variant: 'error',
                    })
                }
            }else{
                if(data.msg) {
                    if(data.msg == 'moderator'){
                        enqueueSnackbar('Учетная запись не активна, обратитесь пожалуйста к администрации для ускоренного подтверждения ваших данных.', {
                            variant: 'info'
                        })
                    }else {
                        enqueueSnackbar(data.msg, {
                            variant: data.status ? 'success' : 'error'
                        })
                    }
                }
            }
        }

        if (isError) {
            enqueueSnackbar('Упс... Ошибка на сервере', {
                variant: 'error',
            })
        }

    }, [data])

    return (

        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {/*<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} src='/images/logoChar.png' />*/}
                    {/*<Avatar sx={{
                        m: 2,
                        bgcolor: 'secondary.main',
                        margin: '20px auto',
                        width: 128,
                        height: 128  }} src='/images/avatar.jpg' />*/ }
                    <Typography component="h2" variant="h6">
                        Бюро счастливых знакомств
                    </Typography>
                    <img style={{
                        margin: '5px auto 10px auto',
                        width: '64px',
                        height: '64px'  }} src='/images/logo.png' />
                    <Typography component="h1" style={{fontSize: '18px'}}>
                        Восстановить пароль
                    </Typography>

                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                        <Email control={control} rules={{ autoComplete: 'email', autoFocus: true}} />

                        <div className='form_group_recaptcha'>
                            <ReCAPTCHA
                                ref={recaptchaRef as React.RefObject<ReCAPTCHA>}
                                size="normal"
                                sitekey={recaptchaKey}
                                style={{textAlign: 'center'}}
                                onChange={onSubmitWithReCAPTCHA}
                            />
                        </div>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 1, mb: 2 }}
                            disabled = {!captchaflag}
                        >
                            Восстановить пароль
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                {/*<Link href="/" variant="body2">*/}
                                <Link to={{ pathname: "/"}} component={RouterLink} variant="body2" >На главную</Link>
                            </Grid>
                            {<Grid item xs sx={{textAlign: 'right'}}>
                                <Link to={{ pathname: "/login"}} component={RouterLink} variant="body2" >Войти</Link>
                            </Grid>}
                            {/* https://vk.me/svastro */}
                            {/*<Grid item xs>
                                <Link href="#" variant="body2">
                                    Забыли пароль?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Регистрация"}
                                </Link>
                            </Grid>*/}
                            <AgreementPopUp welcomeText='Продолжая пользоваться сайтом, вы соглашаетесь на'/>
                        </Grid>
                    </Box>
                </Box>
                <Socialicons />
                <Copyright />
            </Container>
        </ThemeProvider>
    );
}
