import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useSubmitDataMutation } from '../../../services/goroskop';
import { useSnackbar } from 'notistack';
import T from "../../quiz/OnlyText";
import { useState, useEffect } from "react"

function DialogAddCoins(defaultCoins: string | number | undefined, userId: string | number | undefined) {
    const [open, setOpen] = React.useState(false);

    const [coinsNumber, setCoinsNumber] = useState<number>(0);
    //const [moder, setModer] = useState('1')
    const [submitData, { }] = useSubmitDataMutation()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    var existCoins = 0;
    if(typeof(defaultCoins) == "string" || typeof(defaultCoins) == 'number') {
        //@ts-ignore
        existCoins = parseInt(defaultCoins);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // sendData(row.id, row['next_contact_date'] ?? '', 'updateNextContactData.php');
    const sendData = async (id: string, newcoins: string) => {

        let fD = new FormData();

        fD.append('id', id)
        fD.append('coins', newcoins)
        submitData({ name: 'add_coins.php', data: fD }).then((answer:any) => {
            if(answer.data && answer.data[0]){
                enqueueSnackbar(
                    (answer.data[0].msg ? answer.data[0].msg : 'Ошибка передачи данных на сервер'),
                    {variant: answer.data[0].status ? 'success' : 'error'}
                )
                if(answer.data[0].coins){
                    existCoins = answer.data[0].coins
                    setCoinsNumber(existCoins);
                }
            }
        })
    }

    return (
        <React.Fragment>
            <T><strong></strong> Количество доступных ключей: {coinsNumber > 0 ? coinsNumber : (defaultCoins ? defaultCoins : '01')}</T>
            <Button variant="contained" size="small" startIcon={<VpnKeyIcon />} onClick={handleClickOpen}>
                Добавить ключ(и)
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        event.stopPropagation();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData as any).entries());
                        const coins = formJson.coins;
                        if(typeof(userId) == "string" || typeof(userId) == 'number'){
                            let newId = 0;
                            if (typeof userId === "string") {
                                newId = parseInt(userId);
                            }
                            if(newId > 0) {
                                sendData(userId.toString(), coins.toString())
                                handleClose();
                            }
                        }
                    },
                }}
            >
                <DialogTitle>Добавить ключ(и)</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Чтобы добавить ключей пользователю, введите количество, которые Вы хотели бы выдать в поле ниже.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="coins"
                        name="coins"
                        label="Количество ключей"
                        type="number"
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отмена</Button>
                    <Button type="submit">Добавить</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default DialogAddCoins